import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { actions } from "../../actions";
import {
  getPremierCollectionShopDetailsChosenRoomInfo,
  getPremierCollectionShopDetailsChosenRoomInfoIndex,
  getPremierCollectionShopDetailsRoomsInfo,
  getPremierCollectionShopDetailsSelectedLodgingData,
} from "../../reducer/selectors";
import { RoomInfoPicker } from "./component";
import {
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  const chosenRoomInfoIndex = getPremierCollectionShopDetailsChosenRoomInfoIndex(state);
  const chosenRoomInfo = getPremierCollectionShopDetailsChosenRoomInfo(state);
  const lodgingData = getPremierCollectionShopDetailsSelectedLodgingData(state);
  const roomsInfo = getPremierCollectionShopDetailsRoomsInfo(state);
  const fromDate = getFromDate(state);
  const untilDate = getUntilDate(state);

  return {
    chosenRoomInfoIndex,
    chosenRoomInfo,
    lodgingData,
    roomsInfo,
    fromDate,
    untilDate,
  };
};

const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RoomInfoPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRoomInfoPicker = withRouter(
  connector(RoomInfoPicker)
);
