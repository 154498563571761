import React from "react";
import { DesktopPopularAmenitiesConnectorProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { HotelAmenities } from "halifax";
import * as textConstants from "./textConstants";
import { useInView } from "react-intersection-observer";

export interface DesktopPopularAmenitiesProps
  extends DesktopPopularAmenitiesConnectorProps,
    RouteComponentProps {
    variant?: "default" | "lifestyle-collection";
}

export const DesktopPopularAmenities = (props: DesktopPopularAmenitiesProps) => {
  const {
    variant,
    lodging,
    shopDetailsLodgingData,
  } = props;
  // TODO change once BE is updated to return lodging
  const { lodging: lodgingData } = lodging?.lodging ? lodging : { lodging: shopDetailsLodgingData };
  const [amenitiesRef, ] = useInView({ threshold: 0.8 });

    return (
      <div
        className="section-wrapper selected-lodging-amenities-section"
        id="amenities-section"
        ref={amenitiesRef}
      >
        <Box className="left-section">
          <Typography variant="h2" className="amenities-title">
            {textConstants.AMENITIES_TITLE}
          </Typography>
          <Typography variant="body1" className="amenities-subtitle">
            {textConstants.getAmenitiesSubtitle(variant !== "default")}
          </Typography>
          <HotelAmenities
            amenities={lodgingData?.amenities}
            displayCount={6}
            showAmenitiesText={`View More (${
              lodgingData?.amenities && lodgingData?.amenities?.length - 6
            })`}
            hideAmenityIconsInTooltip
          />
        </Box>
        <Box
          className="right-section"
          style={
          !!lodgingData?.media?.[1]
            ? { backgroundImage: `url("${lodgingData.media[1].url}")` }
            : undefined
          }
        ></Box>
      </div>
  );
};
