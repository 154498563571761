import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
  useMultiroomCountText,
} from "@capone/common";
import {
  useExperimentIsVariant,
  useExperimentsById,
} from "@capone/experiments";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  BackToTopButton,
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerRedesign,
  HotelShopRoomTypePickerVariant,
  Icon,
  IconName,
  MultiroomSuffixText,
  TrackingEventControlType,
} from "halifax";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  LodgingPriceFreezeOffer,
  ModalScreens,
  SELECT_HOTEL_ROOM,
  SelectedTravelOfferScreen,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";
import { getEarnTagText } from "../../textConstants";
import { shouldTrackLodgingLocation } from "../../utils/shouldTrackLodgingLocation";
import { MobileShopHeader } from "../MobileShopHeader";
import { MobileRoomPickerRedesignConnectorProps } from "./container";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import "./styles.scss";

export interface IMobileRoomPickerRedesignProps
  extends MobileRoomPickerRedesignConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
  baseHotelPriceFreezeOffer?: LodgingPriceFreezeOffer | null | undefined;
  nonRefundablePolicyOverrideText?: string;
}

export const MobileRoomPickerRedesign = (
  props: IMobileRoomPickerRedesignProps
) => {
  const {
    checkinDate,
    checkoutDate,
    history,
    fetchHotelShop,
    chosenProduct,
    selectRoomType,
    selectedLodging,
    chosenRoomInfoIndex,
    roomsCount,
    largestValueAccount,
    handleReadyToRedirect,
    showOfferBasedOnSelectedLodging,
    roomInfoProductsType,
    baseHotelPriceFreezeOffer,
    isFintechHotelUpdatedUxEnabled,
    isHotelDisplayRoomPricingUpdateEnabled,
    isHotelCfarModelV1Enabled,
    isHotelCfarModelV1RefundableRoomUpdatedCopy,
    isHotelCfarModelV1RefundableRoomUpdatedUX,
    canEarnRewards,
    nightCount,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);
  const expState = useExperiments();
  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.hotelsMultiplier &&
    canEarnRewards;

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_RECENTLY_VIEWED_HOTELS,
    MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const showGlobalMobileNavExperience =
    isGlobalMobileNavExperiment && !isCorpTenant(config.TENANT);

  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const { isAutoApprovalEnabled } = useContext(ClientContext);

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : isApprovalsV2Enabled
    ? "out_of_policy_24hr_review"
    : "out_of_policy";

  useEffect(() => {
    if (checkinDate && checkoutDate) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchHotelShop(history, {
          forceCallHotelAvailability: true,
          fetchSimilarHotels: similarHotelsVariant !== CONTROL,
          includeLocationSearchTerm:
            isRecentlyViewedV1Experiment &&
            recentlyViewedMobileP0Variant !== CONTROL &&
            shouldTrackLodgingLocation(history.location.search),
        });
      }
    }
  }, [checkinDate, checkoutDate]);

  useEffect(() => {
    !chosenProduct && selectRoomType(0, 0);
    setTimeout(() => {
      const id = "chosen-room";
      const yOffset = -122.5;
      const element = document.getElementById(id);
      const y =
        (element?.getBoundingClientRect().top ?? 0) +
        window.pageYOffset +
        yOffset;
      if (chosenRoomInfoIndex || chosenRoomInfoIndex === 0) {
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 50);
  }, []);
  const [showOfferBanner, setShowOfferBanner] = useState(false);

  useEffect(() => {
    setShowOfferBanner(
      isTravelWalletOfferExperiment && showOfferBasedOnSelectedLodging
    );
  }, [showOfferBasedOnSelectedLodging]);

  return (
    <Box>
      <MobileShopHeader />
      {showOfferBanner && (
        <TravelWalletDetailsBanner
          onDismiss={() => setShowOfferBanner(false)}
          offer={selectedLodging?.bestOfferThisLodging!}
          className="mobile-hotel-shop-offer"
          screen={SelectedTravelOfferScreen.HOTEL_DETAILS}
          bannerTextType="shop"
        />
      )}
      <HotelShopRoomTypePickerRedesign
        {...props}
        scrollToPriceFreezeId={baseHotelPriceFreezeOffer?.id || null}
        roomInfoProductsType={
          roomInfoProductsType ?? {
            roomInfoProducts: props.roomInfoProducts,
            variant: HotelShopRoomTypePickerEnum.Default,
          }
        }
        isMobile={true}
        className="b2b"
        onClickContinue={(
          room_type?: string,
          in_policy?: boolean,
          hotel_loyalty_eligible?: boolean
        ) => {
          if (handleReadyToRedirect) {
            const room =
              props.chosenRoomInfo && props.chosenRoomInfo.roomInfo.name;
            handleReadyToRedirect({
              [SELECT_HOTEL_ROOM]: {
                properties: {
                  room_type: room ?? room_type,
                  ...(isCorpTenant(config.TENANT) && {
                    in_policy,
                    hotel_loyalty_eligible,
                  }),
                },
              },
            });
          }
        }}
        reserveRoomPrefixText={
          roomsCount > 1 ? (
            <Typography className="reserve-text">
              Reserve{" "}
              <span className={clsx("rooms-count")}>{roomsCount} Rooms</span>{" "}
              for
            </Typography>
          ) : (
            <Typography className="reserve-text">Select room for</Typography>
          )
        }
        mobileContinueButtonText={
          roomsCount > 1 ? <span>Continue</span> : undefined
        }
        suffixText={<MultiroomSuffixText roomsCount={roomsCount} />}
        earnTagContent={
          showEarnEnhancement ? (
            <>
              <Icon name={IconName.StarIcon} />
              <Typography
                className="earn-tag-text"
                dangerouslySetInnerHTML={{
                  __html: getEarnTagText(
                    largestValueAccount.earn.hotelsMultiplier,
                    largestValueAccount.rewardsBalance.currencyDescription ??
                      largestValueAccount.rewardsBalance.currency
                  ),
                }}
              />
            </>
          ) : undefined
        }
        earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
        variant={
          isFintechHotelUpdatedUxEnabled ? "refundable-room-ux" : "default"
        }
        isHotelDisplayRoomPricingUpdateEnabled={
          isHotelDisplayRoomPricingUpdateEnabled
        }
        isHotelCfarModelV1Enabled={isHotelCfarModelV1Enabled}
        isHotelCfarModelV1RefundableRoomUpdatedCopy={
          isHotelCfarModelV1RefundableRoomUpdatedCopy
        }
        isHotelCfarModelV1RefundableRoomUpdatedUX={
          isHotelCfarModelV1RefundableRoomUpdatedUX
        }
        isApprovalRequired={!isAutoApprovalEnabled}
        onShowOutOfPolicyModal={() =>
          trackEvent(
            getShowOOPModalEvent(ModalScreens.HOTELS_SHOP, "hotels", modalType)
          )
        }
        onClickOutOfPolicyContinue={() =>
          trackEvent(
            getClickContinueOOPModalEvent(
              ModalScreens.HOTELS_SHOP,
              "hotels",
              modalType
            )
          )
        }
        onClickOutOfPolicyCancel={() =>
          trackEvent(
            getClickCancelOOPModalEvent(
              ModalScreens.HOTELS_SHOP,
              "hotels",
              modalType
            )
          )
        }
        isGlobalMobileNavExperiment={showGlobalMobileNavExperience}
        roomNameSuffixText={
          isMultiroomAmadeus ? useMultiroomCountText(roomsCount) : ""
        }
        roomsCount={roomsCount}
        onViewCorpCompareBar={onOpenCompareBarTooltip(
          ViewedCorpRateDescriptorEntryPoints.HOTELS_ROOM_DETAILS
        )}
      />
      {showGlobalMobileNavExperience && (
        <BackToTopButton bottom={(nightCount || 0) >= 3 ? undefined : 100} />
      )}
    </Box>
  );
};
