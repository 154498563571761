import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { IShopOverviewProps } from "../../components/ShopOverview/component";
import { IShopWhatsIncludedProps } from "../../components/ShopWhatsIncluded/component";
import { IShopContactInformationProps } from "../../components/ShopContactInformation/component";
import { IShopWhatToExpectProps } from "../../components/ShopWhatToExpect/component";

export const getExperiencesShopCallState = (state: IStoreState) =>
  state.experiencesShop.experiencesShopCallState;

export const getSelectedExperienceId = (state: IStoreState) =>
  state.experiencesShop.selectedExperienceId;

export const getExperiencesShopDetails = (state: IStoreState) =>
  state.experiencesShop.experiencesShopDetails;

export const getExperienceName = createSelector(
  getExperiencesShopDetails,
  (experiencesShopDetails) => {
    if (!experiencesShopDetails) return null;

    return experiencesShopDetails.name;
  }
);

export const getExperienceProviderName = createSelector(
  getExperiencesShopDetails,
  (experiencesShopDetails) => {
    if (!experiencesShopDetails) return null;

    return experiencesShopDetails.supplierInfo.name;
  }
);

export const getExperiencesShopOverview = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopOverviewProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      title: experienceShopDetails.name,
      overview: experienceShopDetails.overview,
      rating: experienceShopDetails.rating,
      duration: experienceShopDetails.duration,
      locationLogistics: experienceShopDetails.logistics,
      language: experienceShopDetails.languageGuides
        ? experienceShopDetails.languageGuides[0].language
        : "English",
      provider: experienceShopDetails.supplierInfo.name,
    };
  }
);

export const getExperiencesShopWhatsIncluded = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopWhatsIncludedProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      additionalInformation: experienceShopDetails.additionalInformation,
      knowBeforeYouBook: experienceShopDetails.knowBeforeYouBook,
      inclusionsAndExclusions: experienceShopDetails.inclusionsAndExclusions,
    };
  }
);

export const getExperiencesShopContactInfo = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopContactInformationProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      supplierEmail: experienceShopDetails.supplierInfo.email,
      supplierPhone: experienceShopDetails.supplierInfo.phone,
    };
  }
);

export const getExperiencesShopWhatToExpect = createSelector(
  getExperiencesShopDetails,
  (experienceShopDetails): IShopWhatToExpectProps | null => {
    if (!experienceShopDetails) return null;

    return {
      isSkeleton: false,
      itinerary: experienceShopDetails.itinerary,
    };
  }
);

// export const getExperiencesShopBookableItemsToDisplay = createSelector(
//   getExperiencesShopDetails,
//   (
//     experiencesShopDetails
//   ): {
//     experienceDailyPrice: ExperienceDailyPrice;
//     startTimes: string[];
//   } | null => {
//     if (!experiencesShopDetails) return null;

//     const bookableItems = experiencesShopDetails.bookableItems;
//     const dailyPrices = experiencesShopDetails.dailyPrices;

//     const parsedBookableItems = [];
//     dailyPrices.slice(0, 4).map((dailyPrice) => {
//       bookableItems.map(() => {

//       })
//     })
//     return null;
//   }
// );
