import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { DesktopShopDetails } from "./component";
import {
  getPremierCollectionShopDetailsCallState,
  getPremierCollectionShopDetailsSelectedLodgingData,
  hasPremierCollectionShopDetailsFailed,
  PremierCollectionShopDetailsCallState,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => ({
  lodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
  hotelShopDetailsCallInProgress:
    getPremierCollectionShopDetailsCallState(state) ===
    PremierCollectionShopDetailsCallState.InProcess,
  hasFailed: hasPremierCollectionShopDetailsFailed(state),
});

export const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type DesktopShopDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopShopDetails = withRouter(connector(DesktopShopDetails));
