export const BACK_TO_RESULTS = "Back to results";

export const WHAT_TO_EXPECT = "What to expect";
export const CANCELLATION_POLICY = "Cancellation policy";
export const WHATS_INCLUDED = "What's included";
export const CUSTOMER_REVIEWS = "Customer Reviews";
export const TRAVELERS_PHOTOS = "Traveler Photos";
export const CONTACT_INFORMATION = "Contact Information";
export const COMPARE_OTHER_SIMILAR_EXPERIENCES =
  "Compare other similar experiences";

export const SHOP_DETAIL_HEADER_TEXT = (
  experienceName: string,
  locationName: string
) => `${experienceName} in ${locationName}`;

export const PARSE_TO_MINUTES = (minutes: number) => `${minutes} minutes`;

export const STARTING_POINT = "Starting point";

export const PASS_BY = " (Pass by)";
export const READ_MORE = "Read more";
export const READ_LESS = "Read less";

export const VIEW_DETAILS = "View details";
export const HIDE_DETAILS = "Hide details";
