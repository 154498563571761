import { Box, Divider, Typography } from "@material-ui/core";
import {
  ActionLink,
  BackToTopButton,
  getCancellationPolicyInfo,
  HotelAmenities,
  HotelCarousel,
  HotelDescription,
  HotelName,
  HotelOverview,
  HotelShopRoomTypePickerVariant,
  HotelTripAdvisorReviews,
  Icon,
  IconName,
  MobileHotelShopRoomTypePickerPanelSkeleton,
  PageTabNavigations,
  PremiumStaysInfoAccordian,
  RoomPriceAndRewardsDisplayRedesign,
  VoidWindowNotice,
} from "halifax";
import React, { useEffect, useMemo } from "react";
import { RewardsAccountSelection } from "../../../rewards/components";
import clsx from "clsx";
import { MobileShopConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import { PremierCollectionShopStep } from "../../reducer";
import * as textConstants from "./textConstants";
import { useInView } from "react-intersection-observer";
import "./styles.scss";
import { MobileShopHeader } from "../MobileShopHeader";
import { MobilePremierCollectionShopRoomTypePickerPanel } from "../MobilePremierCollectionShopRoomTypePickerPanel";
import { MobileRoomPicker } from "../MobileRoomPicker";
import { PremierCollectionShopExpandedMap } from "../PremierCollectionShopExpandedMap";
import {
  AmenityEnum,
  CancellationReason,
  CustomerAccountRole,
  LodgingAddressExact,
  LodgingCollectionEnum,
  SelectedTravelOfferScreen,
} from "redmond";
import { ShopErrorModal } from "../ShopErrorModal";
import {
  AVAILABLE,
  FINTECH_HOTEL_UX_UPDATED,
  getExperimentVariant,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  HOTELS_SHOP_IMAGE_MODAL,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { MobileHotelPhotoModal } from "../MobileHotelPhotoModal";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  TravelWalletDetailsBanner,
  TravelWalletDrawer,
} from "../../../travel-wallet/components";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../../textConstants";
export interface IMobileShopProps
  extends MobileShopConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: () => void;
  variant?: "default" | "lifestyle-collection";
}

export const MobileShop = (props: IMobileShopProps) => {
  const {
    lodging,
    premierCollectionShopCallInProgress,
    chosenProduct,
    hasFailed,
    premierCollectionShopStep,
    viewedPremierCollectionDetailsProperties,
    roomInfoProductsType,
    handleReadyToRedirect,
    variant = "default",
    largestEarnAccount,
    showOfferBasedOnSelectedLodging,
    nightCount,
    rewardsKey,
    cheapestProduct,
    cancellationSummary,
  } = props;
  const [
    showExpandedPremierCollectionMap,
    setShowExpandedPremierCollectionMap,
  ] = React.useState(false);
  const [clickedSectionId, setClickedSectionId] = React.useState<string>();
  const [galleryRef, galleryInView] = useInView({ threshold: 1 });
  const [aboutRef, aboutInView] = useInView({ threshold: 1 });
  const [amenitiesRef, amenitiesInView] = useInView({ threshold: 1 });
  const [reviewsRef, reviewsInView] = useInView({ threshold: 1 });
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const [showOfferBanner, setShowOfferBanner] = React.useState(false);

  const expState = useExperiments();
  const hotelShopImageModal = getExperimentVariant(
    expState.experiments,
    HOTELS_SHOP_IMAGE_MODAL
  );
  const isHotelShopImageModal = useMemo(
    () => hotelShopImageModal === AVAILABLE,
    [hotelShopImageModal]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const fintechHotelUpdatedUXExperiment = getExperimentVariant(
    expState.experiments,
    FINTECH_HOTEL_UX_UPDATED
  );
  const isFintechHotelUpdatedUXExperiment = useMemo(
    () => fintechHotelUpdatedUXExperiment === AVAILABLE,
    [fintechHotelUpdatedUXExperiment]
  );

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(() => {
    return californiaBill644Variant === AVAILABLE;
  }, [californiaBill644Variant]);

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const includeTaxesAndFeesInTotalPrice =
    lodging?.price?.displayTaxesAndFeesIncluded;

  useEffect(() => {
    setShowOfferBanner(
      isTravelWalletOfferExperiment && showOfferBasedOnSelectedLodging
    );
  }, [showOfferBasedOnSelectedLodging]);

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        textConstants.getCfarSecondaryText
      )
    : null;

  const selectedTab = React.useMemo(() => {
    if (
      galleryInView &&
      (clickedSectionId ? clickedSectionId === "#gallery-section" : true)
    ) {
      return 0;
    }
    if (
      aboutInView &&
      (clickedSectionId ? clickedSectionId === "#about-section" : true)
    ) {
      return 1;
    }
    if (
      amenitiesInView &&
      (clickedSectionId ? clickedSectionId === "#amenities-section" : true)
    ) {
      return 2;
    }
    if (
      reviewsInView &&
      (clickedSectionId ? clickedSectionId === "#reviews-section" : true)
    ) {
      return 3;
    }

    return undefined;
  }, [galleryInView, aboutInView, amenitiesInView, reviewsInView]);
  const scrollToSection = (sectionId: string) => {
    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT =
      document
        .querySelector(".pc-shop-progress-header-container")
        ?.getBoundingClientRect().height ?? 0;
    const MARGIN = 20;
    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);
    const element = document.getElementById(sectionId);

    const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;
    setClickedSectionId(sectionId);
    setTimeout(() => {
      setClickedSectionId(undefined);
    }, 500);
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const tabs = [
    { label: "Gallery", onClick: () => scrollToSection("gallery-section") },
    { label: "About", onClick: () => scrollToSection("about-section") },
    { label: "Amenities", onClick: () => scrollToSection("amenities-section") },
    ...(lodging &&
    lodging.lodging?.tripAdvisorReviews &&
    lodging.lodging.tripAdvisorReviews?.comments?.length > 0
      ? [
          {
            label: "Reviews",
            onClick: () => scrollToSection("reviews-section"),
          },
        ]
      : []),
  ];

  React.useEffect(() => {
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      document!.getElementById("nebula_div_btn")!.style.display = "none";
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [document.getElementById("nebula_div_btn")]);

  useEffect(() => {
    if (lodging && !!lodging.lodging.amenities.length) {
      const paidBreakfastIndex = lodging.lodging?.amenities.findIndex(
        (amenity) => amenity.amenity === AmenityEnum.PaidBreakfast
      );

      if (paidBreakfastIndex && paidBreakfastIndex > -1) {
        lodging.lodging?.amenities.splice(paidBreakfastIndex, 1);
      }
    }
  }, [lodging]);

  const renderRewardsAccount = () => {
    return (
      <Box className="mobile-pc-shop-rewards-account-contents">
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
    );
  };

  const renderSkeleton = () => (
    <Box className={"mobile-pc-shop-skeleton"}>
      <Box>
        <HotelName isSkeleton />
      </Box>
      <HotelCarousel isSkeleton />
      <Box className={"section-wrapper"}>
        <HotelOverview isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelAmenities isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelName isSkeleton />
        <HotelCarousel isSkeleton />
      </Box>
      <MobileHotelShopRoomTypePickerPanelSkeleton />
    </Box>
  );

  const renderShop = (premierCollectionShopStep: PremierCollectionShopStep) => {
    const { lodging: lodgingData } = lodging || {};
    return (
      <Box className={"mobile-pc-shop-container"}>
        {lodging &&
        premierCollectionShopStep === PremierCollectionShopStep.HotelInfo ? (
          <>
            <MobileShopHeader isVRForPremiumCardHoldersEnabled={false} />

            {renderRewardsAccount()}

            {isHotelShopImageModal && (
              <MobileHotelPhotoModal
                openModal={openPhotoModal}
                setOpenModal={setOpenPhotoModal}
              />
            )}

            <div
              id="gallery-section"
              onClick={() => {
                if (isHotelShopImageModal) {
                  trackEvent({
                    eventName: "hotel_shop_image_modal",
                    properties: {
                      ...viewedPremierCollectionDetailsProperties,
                      is_pc:
                        lodging?.lodgingCollection ===
                        LodgingCollectionEnum.Premier,
                      is_lc:
                        lodging?.lodgingCollection ===
                        LodgingCollectionEnum.Lifestyle,
                    },
                  });
                  setOpenPhotoModal(true);
                }
              }}
              ref={galleryRef}
            >
              <HotelCarousel media={lodgingData?.media} />
            </div>

            <PageTabNavigations
              navItems={tabs}
              selectedTab={selectedTab}
              className="pc-shop-navigation"
            />

            {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
              <Box
                className={clsx("shop-benefits", {
                  "lifestyle-collection": variant === "lifestyle-collection",
                })}
              >
                <Box className="shop-benefits-logo">
                  <Icon
                    name={
                      variant === "lifestyle-collection"
                        ? IconName.LifestyleCollectionHorizontalIcon
                        : IconName.PremierCollectionHorizontalIcon
                    }
                  />
                </Box>

                <Typography className="shop-benefits-list">
                  {textConstants.getBenefitsList(
                    largestEarnAccount.earn.hotelsMultiplier,
                    variant,
                    largestEarnAccount.customerAccountRole ===
                      CustomerAccountRole.Primary
                  )}
                </Typography>
                <Box className="additional-benefits">
                  <Icon name={IconName.GiftOutline} />
                  <Typography className="additional-benefits-text">
                    {variant === "lifestyle-collection"
                      ? textConstants.LC_ADDITIONAL_BENEFITS
                      : textConstants.ADDITIONAL_BENEFITS}
                  </Typography>
                </Box>
              </Box>
            )}
            {showFree24HourCancel && <VoidWindowNotice />}
            {showOfferBanner && (
              <TravelWalletDetailsBanner
                offer={lodging?.bestOfferThisLodging!}
                showButton={false}
                className="hotel-shop-offer"
                screen={SelectedTravelOfferScreen.PREMIUM_STAYS_DETAILS}
                {...props}
              />
            )}
            <div
              className={"section-wrapper description-section"}
              id="about-section"
              ref={aboutRef}
            >
              <Box
                className={clsx("description-wrapper", {
                  "include-taxes-and-fees": includeTaxesAndFeesInTotalPrice,
                })}
              >
                <Typography variant="h2" className="description-title">
                  About the
                  <span className="regular-font"> {lodgingData?.name}</span>
                </Typography>
                {
                  lodgingData && (
                  <PremierCollectionShopExpandedMap
                    lodgingData={lodgingData}
                    showExpandedPremierCollectionMap={
                      showExpandedPremierCollectionMap
                    }
                    setShowExpandedPremierCollectionMap={
                      setShowExpandedPremierCollectionMap
                    }
                    isMobile
                  />
                )}
                <ActionLink
                  className="lodging-address-link"
                  content={
                    <Box className="lodging-address-link-content">
                      <Icon name={IconName.B2BMapPin} />
                      <Typography className="lodging-address">
                        {(lodgingData?.address as LodgingAddressExact).line1}
                      </Typography>
                    </Box>
                  }
                  onClick={() => setShowExpandedPremierCollectionMap(true)}
                />
                {includeTaxesAndFeesInTotalPrice &&
                  (chosenProduct || cheapestProduct) && (
                    <RoomPriceAndRewardsDisplayRedesign
                      roomProduct={chosenProduct ?? cheapestProduct}
                      nightCount={nightCount || 0}
                      rewardsKey={rewardsKey}
                      lodging={lodging}
                      useSlashForPer
                      hasChosenProduct={!!chosenProduct}
                      isMobile
                    />
                  )}
                <Divider className={"description-divider"} />
                <HotelDescription description={lodging?.lodging?.description} />
              </Box>
            </div>
            <div
              className="section-wrapper selected-lodging-amenities-section"
              id="amenities-section"
              ref={amenitiesRef}
            >
              <Box className="left-section">
                <Typography variant="h2" className="amenities-title">
                  {textConstants.AMENITIES_TITLE}
                </Typography>
                <Typography variant="body1" className="amenities-subtitle">
                  {textConstants.AMENITIES_SUBTITLE}
                </Typography>
                <HotelAmenities
                  amenities={lodgingData?.amenities}
                  displayCount={6}
                  hideAmenityIconsInTooltip
                  showAmenitiesText={`View All Amenities (${
                    lodgingData?.amenities && lodgingData?.amenities?.length - 6
                  })`}
                />
                <Box className="additional-benefits">
                  <Icon name={IconName.GiftOutline} />
                  <Typography className="additional-benefits-text">
                    {textConstants.ADDITIONAL_BENEFITS}
                  </Typography>
                </Box>
              </Box>
              <Box className="right-section"></Box>
            </div>

            {lodgingData?.tripAdvisorReviews &&
              lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
                <div
                  className={clsx("section-wrapper reviews-section")}
                  id="reviews-section"
                  ref={reviewsRef}
                >
                  <Typography variant="h2">{textConstants.REVIEWS}</Typography>
                  <HotelTripAdvisorReviews
                    lodgingData={lodgingData}
                    trim={1000}
                  />
                </div>
              )}
            {cancellationPolicyInfo && (
              <Box
                className={clsx(
                  "section-wrapper",
                  "cancellation-policy-section"
                )}
              >
                <Typography
                  variant="h4"
                  className="hotel-shop-cancellation-policy-heading"
                >
                  {cancellationPolicyInfo.primaryText}
                </Typography>
                <Typography variant="subtitle2">
                  {cancellationPolicyInfo.secondaryText}
                </Typography>
              </Box>
            )}
            <PremiumStaysInfoAccordian
              variant="only-experience-credit"
              isMobile
            />

            {!isGlobalMobileNavExperiment && isTravelWalletCreditsExperiment ? (
              <TravelWalletDrawer />
            ) : undefined}

            <MobilePremierCollectionShopRoomTypePickerPanel />
          </>
        ) : (
          lodging && (
            <MobileRoomPicker
              roomInfoProductsType={roomInfoProductsType}
              handleReadyToRedirect={handleReadyToRedirect}
              isGlobalMobileNavExperiment={isGlobalMobileNavExperiment}
              isVRForPremiumCardHoldersEnabled={false}
              nonRefundablePolicyOverrideText={
                showFree24HourCancel
                  ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                  : undefined
              }
              isFintechHotelUpdatedUxEnabled={isFintechHotelUpdatedUXExperiment}
            />
          )
        )}
        {isGlobalMobileNavExperiment ? (
          <BackToTopButton
            bottom={
              premierCollectionShopStep === PremierCollectionShopStep.HotelInfo
                ? 115
                : undefined
            }
          />
        ) : undefined}
      </Box>
    );
  };
  return !hasFailed ? (
    <Box
      className={clsx("mobile-pc-shop-wrapper", {
        "lifestyle-collection": variant.includes("lifestyle-collection"),
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
    >
      {premierCollectionShopCallInProgress
        ? renderSkeleton()
        : renderShop(premierCollectionShopStep)}
    </Box>
  ) : (
    <ShopErrorModal />
  );
};
