import React from "react";
import styles from "./styles.module.scss";
import { Box, Divider, Link, Typography } from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";
import {
  ExperienceLocation,
  ExperienceLogistics,
  ReviewSummary,
} from "redmond";
import {
  AvailabilityReviewRating,
  AvailabilityDuration,
  AvailabilityLanguage,
  Icon,
  IconName,
  truncateString,
} from "halifax";

export interface IShopOverviewProps {
  isSkeleton?: boolean;
  title?: string;
  overview?: string;
  rating?: ReviewSummary;
  duration?: number;
  locationLogistics?: ExperienceLogistics;
  language?: string;
  provider?: string;
}

const Skeleton = () => (
  <Box className={styles["experiences-shop-skeleton"]}>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopOverview = (props: IShopOverviewProps) => {
  const {
    isSkeleton = false,
    title,
    overview,
    rating,
    duration,
    locationLogistics,
    language,
    provider,
  } = props;

  const [showDescription, setShowDescription] = React.useState(false);

  if (
    !title ||
    !overview ||
    !rating ||
    duration === undefined ||
    !locationLogistics ||
    !provider ||
    !language
  ) {
    return null;
  }

  const truncatedOverview = `${overview.substring(0, 300)} (...)`;
  const sameAsStartLocation =
    locationLogistics.startLocations[0].address ===
    locationLogistics.endLocations[0].address;

  const renderAddress = (
    location: ExperienceLocation,
    sameAsStart?: boolean
  ) => {
    const [line1, ...rest] = location.address.split(",");
    const line2 = rest.join(",");

    return (
      <Box className={styles["experiences-shop-overview-map-address"]}>
        {sameAsStart ? (
          <Typography variant="body2">
            {"This activity ends at the starting point."}
          </Typography>
        ) : (
          <>
            {location.name && (
              <Typography variant="body2">{location.name}</Typography>
            )}
            <Typography variant="body2">{line1}</Typography>
            <Typography variant="body2">{line2}</Typography>
          </>
        )}
      </Box>
    );
  };

  return (
    <Box className={styles["experiences-shop-overview-container"]}>
      {/* TOP SECTION */}
      <Box className={styles["experiences-shop-overview-subtext"]}>
        <AvailabilityReviewRating
          reviewCount={rating.numberOfReviews}
          scaledScore={4.5} //// TODO: Remove this it's to deal with mock data
          // scaledScore={props.rating.reviewAverage}
          shortReviews
        />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        <AvailabilityDuration duration={duration} isSkeleton={isSkeleton} />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        <AvailabilityLanguage language={language} isSkeleton={isSkeleton} />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        <Typography className={styles["experiences-shop-overview-title"]}>
          {truncateString(provider, 50)}
        </Typography>
      </Box>
      {/* MIDDLE SECTION */}
      <Box className={styles["experiences-shop-overview-availability"]}>
        <Typography variant="h6">{"Availability"}</Typography>
      </Box>
      {/* BOTTOM SECTION */}
      <Box className={styles["experiences-shop-overview-bottom-section"]}>
        <Typography variant="h6">{"Overview"}</Typography>
        <Typography
          variant="body2"
          className={styles["experiences-shop-overview-text"]}
        >
          {!showDescription ? truncatedOverview : overview}
        </Typography>
        <Link
          component="button"
          onClick={() => setShowDescription(!showDescription)}
          className={styles["experiences-shop-overview-button"]}
        >
          {showDescription ? "Show less" : "Read more"}
        </Link>
        <Box className={styles["experiences-shop-overview-map-section"]}>
          <Box
            className={styles["experiences-shop-overview-map-starting-point"]}
          >
            <Typography
              variant="h6"
              className={styles["experiences-shop-overview-map-titles"]}
            >
              <Icon name={IconName.B2BMapPin} />
              {"Starting point"}
            </Typography>
            {renderAddress(locationLogistics.startLocations[0])}
          </Box>
          <Box className={styles["experiences-shop-overview-map-end-point"]}>
            <Typography
              variant="h6"
              className={styles["experiences-shop-overview-map-titles"]}
            >
              <Icon name={IconName.FlagIcon} />
              {"End point"}
            </Typography>
            {renderAddress(
              locationLogistics.endLocations[0],
              sameAsStartLocation
            )}
          </Box>
          <Box
            className={styles["experiences-shop-overview-map"]}
            onClick={() => {
              // if (onMapClick) onMapClick();
            }}
          ></Box>
        </Box>
      </Box>
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );
};
