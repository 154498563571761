import React, { useContext, useLayoutEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import {
  IHotelLoyaltyProgram,
  getUpdatedTraveler,
  isCorpTenant,
} from "@capone/common";
import { ICorpPerson, IPerson, ITravelerStepErrors, UserInfo } from "redmond";
import {
  CloseButton,
  DefaultProfileChip,
  TravelerInfoForm,
  TravelerSelectStep,
  UnsavedChangesModal,
} from "halifax";
import { HotelEditTravelerInfoForm } from "./HotelEditTravelerInfoForm";
import { SelectedTravelers } from "../component";
import { IUpdateUserPassengerArgs } from "../../../../actions/actions";
import { TRAVELER_INFO_SUBTITLE, TRAVELER_INFO_TITLE } from "../constants";
import { config } from "../../../../../../api/config";
import { CORPORATE_ROLE_ADMIN } from "b2b-base/src/utils/constants";
import { ClientContext } from "../../../../../../App";

export interface TravelerSelectDropdownProps {
  disableEditTravelerInfo: boolean;
  selectedTravelers: SelectedTravelers;
  updateSelectedTravelersList: (traveler: ICorpPerson | IPerson) => void;
  removeSelectedTraveler: (traveler: ICorpPerson | IPerson) => void;
  setProgress: (progress: TravelerSelectStep) => void;
  travelers: ICorpPerson[] | IPerson[];
  index: number;
  updateUserPassenger: (
    payload: IUpdateUserPassengerArgs,
    addDriversLicense?: boolean,
    isNewTraveler?: boolean,
    defaultTravelerId?: string,
    entryPoint?: string
  ) => void;
  showErrors: ITravelerStepErrors;
  setShowErrors: (showErrors: ITravelerStepErrors) => void;
  isLoyaltyEligible: boolean;
  loyaltyProgram: IHotelLoyaltyProgram | null;
  loading: boolean;
  userRoles?: string[];
  isAmadeusProvider: boolean;
}

export const TravelerSelectDropdown = ({
  travelers,
  disableEditTravelerInfo,
  selectedTravelers,
  updateSelectedTravelersList,
  removeSelectedTraveler,
  setProgress,
  index,
  updateUserPassenger,
  showErrors,
  setShowErrors,
  isLoyaltyEligible,
  loyaltyProgram,
  loading,
  userRoles,
  isAmadeusProvider,
}: TravelerSelectDropdownProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo } = clientContext;
  const [selectedTraveler, setSelectedTraveler] = useState<
    ICorpPerson | IPerson | null
  >(selectedTravelers[index] ?? null);
  const [prefillPassenger, setPrefillPassenger] = useState<UserInfo | null>(
    sessionInfo?.userInfo ?? null
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [closeFormTrigger, setCloseFormTrigger] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  useLayoutEffect(() => {
    setSelectedTraveler(selectedTravelers[index] ?? null);
  }, [selectedTravelers, index]);

  const handleChange = (
    traveler: ICorpPerson | IPerson,
    newLoyaltyNumber?: string,
    newEmail?: string
  ) => {
    const updatedTraveler = getUpdatedTraveler(
      traveler,
      loyaltyProgram,
      newLoyaltyNumber,
      newEmail
    );
    updateSelectedTravelersList(updatedTraveler);
  };

  const isSelectTravelerError =
    showErrors?.corpPrimaryTraveler && selectedTravelers.length === 0;

  const travelersWithButton: (IPerson | ICorpPerson | string)[] = [
    ...travelers,
    "Add new traveler",
  ];

  const handleCloseForm = () => {
    setIsEditModalOpen(false);
  };

  const handleHasUnsavedChanges = (unsavedChanges: boolean) => {
    setCloseFormTrigger(false);
    if (unsavedChanges) {
      setShowUnsavedChangesModal(unsavedChanges);
    } else {
      handleCloseForm();
    }
  };

  const handleOnClose = () => {
    setCloseFormTrigger(true);
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        {!selectedTraveler && (
          <div>
            <fieldset>
              <Autocomplete
                loading={loading}
                id="hotel-traveler-select"
                className="hotel-traveler-select"
                value={selectedTraveler}
                autoComplete
                options={travelersWithButton}
                fullWidth
                disabled={disableEditTravelerInfo}
                blurOnSelect
                onChange={(_, value, reason) => {
                  if (typeof value === "string") {
                    setPrefillPassenger(null);
                    setSelectedTraveler(null);
                    setIsEditModalOpen(true);
                  } else {
                    setSelectedTraveler(value);
                    setPrefillPassenger(sessionInfo?.userInfo ?? null);
                    if (value) {
                      if (reason === "clear") {
                        removeSelectedTraveler(value);
                      } else if (
                        "isMissingInfo" in value &&
                        value.isMissingInfo
                      ) {
                        setIsEditModalOpen(true);
                      } else {
                        updateSelectedTravelersList(value);
                        setProgress(TravelerSelectStep.TravelerInfoForm);
                        setShowErrors({
                          phone: showErrors?.phone,
                          email: showErrors?.email,
                          travelerSelect: showErrors?.travelerSelect,
                          corpPrimaryTraveler: false,
                        });
                      }
                    }
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === "string") return "";
                  return `${option.givenName} ${option.surname}`;
                }}
                getOptionDisabled={(option) =>
                  typeof option !== "string" &&
                  Boolean(
                    selectedTravelers.find(
                      (traveler) => traveler.id === option.id
                    )
                  )
                }
                renderOption={(option) => {
                  if (typeof option === "string") {
                    return (
                      <Typography
                        className="new-traveler-option"
                        variant="body1"
                      >
                        <Add fontSize="small" />
                        {option}
                      </Typography>
                    );
                  }
                  return (
                    <>
                      {option.givenName} {option.surname}
                      {"isDefaultProfile" in option &&
                        option.isDefaultProfile && <DefaultProfileChip />}
                    </>
                  );
                }}
                ListboxProps={{
                  className: "traveler-select-dropdown-list",
                }}
                renderInput={({ InputProps, ...params }) => (
                  <TextField
                    label="Select a traveler"
                    className="traveler-select-dropdown"
                    variant="filled"
                    required={index === 0}
                    InputProps={{
                      disableUnderline: true,
                      ...InputProps,
                    }}
                    error={isSelectTravelerError}
                    helperText={
                      isSelectTravelerError
                        ? "Please select a primary traveler to continue"
                        : undefined
                    }
                    {...params}
                  />
                )}
                filterOptions={(options, params) => {
                  const filtered = options.filter(
                    (option) =>
                      typeof option === "string" ||
                      `${option.givenName} ${option.surname}`
                        .toLowerCase()
                        .includes(params.inputValue.toLowerCase())
                  );

                  return filtered;
                }}
              />
            </fieldset>
          </div>
        )}

        {selectedTraveler && (
          <HotelEditTravelerInfoForm
            traveler={selectedTraveler}
            handleChange={handleChange}
            disableEditTravelerInfo={disableEditTravelerInfo}
            handleRemove={(traveler) => {
              removeSelectedTraveler(traveler);
              setSelectedTraveler(null);
            }}
            setIsEditModalOpen={setIsEditModalOpen}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            isLoyaltyEligible={isLoyaltyEligible}
            isAmadeusProvider={isAmadeusProvider}
          />
        )}
      </form>
      <Dialog
        open={isEditModalOpen}
        onClose={handleOnClose}
        maxWidth="md"
        fullWidth
        className="edit-traveler-info-modal"
      >
        <CloseButton onClick={handleOnClose} />
        <DialogContent className="edit-traveler-info-modal-content">
          <>
            <TravelerInfoForm
              traveler={selectedTraveler ?? undefined}
              travelerInfoTitle={TRAVELER_INFO_TITLE(!selectedTraveler)}
              travelerInfoSubtitle={TRAVELER_INFO_SUBTITLE}
              showGenderField
              showNationalityField
              additionalInfoTitle={"additional info title"}
              className="traveler-select-dropdown-edit-form"
              handleUpdatePassenger={(
                traveler: ICorpPerson | IPerson,
                isNewTraveler?: boolean
              ) => {
                updateUserPassenger({ person: traveler }, false, isNewTraveler);
                updateSelectedTravelersList(traveler);
                setSelectedTraveler(traveler);
                setIsEditModalOpen(false);
              }}
              travelerInfoSectionTitle={"Traveler Information"}
              showHotelLoyaltySection
              showContactEmailSection
              tenant={config.TENANT}
              isUserAdmin={userRoles?.includes(CORPORATE_ROLE_ADMIN)}
              closeFormTrigger={closeFormTrigger}
              handleUnsavedChanges={handleHasUnsavedChanges}
              prefillPassenger={prefillPassenger ?? undefined}
            />
            {isCorpTenant(config.TENANT) && (
              <UnsavedChangesModal
                open={showUnsavedChangesModal}
                onClose={() => setShowUnsavedChangesModal(false)}
                onConfirm={() => {
                  setShowUnsavedChangesModal(false);
                  handleCloseForm();
                }}
                onCancel={() => {
                  setShowUnsavedChangesModal(false);
                }}
              />
            )}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};
