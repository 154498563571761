import React from "react";
import { Box } from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";

export interface IShopTravelerPhotosProps {
  isSkeleton: boolean;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="carousel" />
    </Box>
  </Box>
);

export const ShopTravelerPhotos = (props: IShopTravelerPhotosProps) => {
  const { isSkeleton } = props;

  return isSkeleton ? <Skeleton /> : null;
};
