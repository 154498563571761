import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";
import {
  ExperienceItinerary,
  ExperienceItineraryEnum,
  ExperienceItineraryLineItem,
  ExperienceItineraryLineItems,
} from "redmond";
import styles from "./styles.module.scss";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { Icon, IconName, truncateString } from "halifax";
import * as textConstants from "../../textConstants";

export interface IShopWhatToExpectProps {
  isSkeleton: boolean;
  itinerary?: ExperienceItinerary;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
  </Box>
);

export const ShopWhatToExpect = (props: IShopWhatToExpectProps) => {
  const { isSkeleton, itinerary } = props;

  if (!itinerary) {
    return null;
  }

  const description = itinerary.description;

  const [showDescription, setShowDescription] = React.useState(false);

  const renderLineItemsItinerary = (
    lineItems: ExperienceItineraryLineItem[]
  ) => {
    let indexToShow = 0;
    const lineItemsItinerary: React.JSX.Element[] = [];
    lineItems.forEach((lineItem, index) => {
      const [showDetails, setShowDetails] = React.useState(false);
      const showIcon = lineItem.durationMinutes && lineItem.durationMinutes > 0;
      if (showIcon) {
        indexToShow = indexToShow + 1;
      }
      lineItemsItinerary.push(
        <TimelineItem>
          <TimelineSeparator>
            {showIcon ? (
              <TimelineDot className={styles["timeline-dot-with-content"]}>
                <Typography
                  className={styles["time-dot-content"]}
                  variant="body1"
                >
                  {indexToShow}
                </Typography>
              </TimelineDot>
            ) : (
              <TimelineDot className={styles["timeline-dot"]} />
            )}
            {index < lineItems.length - 1 && (
              <TimelineConnector className={styles["timeline-connector"]} />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Box className={styles["timeline-content-container"]}>
              <Box className={styles["timeline-content-title-container"]}>
                <Typography
                  className={styles["timeline-content-title"]}
                  variant="h6"
                  component="span"
                >
                  {lineItem.location}
                </Typography>
                {lineItem.passByWithoutStopping && (
                  <Typography
                    variant="h6"
                    component="span"
                    className={styles["timeline-content-title-subtext"]}
                  >
                    {textConstants.PASS_BY}
                  </Typography>
                )}
              </Box>
              {lineItem.durationMinutes && (
                <Typography
                  variant="body2"
                  component="span"
                  className={styles["timeline-content-duration"]}
                >
                  {textConstants.PARSE_TO_MINUTES(lineItem.durationMinutes)}
                </Typography>
              )}
              {lineItem.description && (
                <Box className={styles["timeline-content-description"]}>
                  {showDetails && (
                    <Typography
                      variant="body1"
                      className={
                        styles["timeline-content-description-expanded"]
                      }
                    >
                      {lineItem.description}
                    </Typography>
                  )}
                  <Link
                    component="button"
                    onClick={() => setShowDetails(!showDetails)}
                    className={styles["timeline-view-details-link"]}
                  >
                    {showDetails
                      ? textConstants.HIDE_DETAILS
                      : textConstants.VIEW_DETAILS}
                  </Link>
                </Box>
              )}
            </Box>
          </TimelineContent>
        </TimelineItem>
      );
    });

    return lineItemsItinerary;
  };

  const renderLineItems = (lineItems: ExperienceItineraryLineItem[]) => {
    if (lineItems.length <= 0) {
      return null;
    }

    return (
      <Box
        className={styles["experiences-shop-what-to-expect-timeline-container"]}
      >
        <Timeline align="left">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={styles["timeline-dot-with-icon"]}>
                <Icon name={IconName.B2BMapPin} />
              </TimelineDot>
              <TimelineConnector className={styles["timeline-connector"]} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="h6" component="span">
                {textConstants.STARTING_POINT}
              </Typography>
            </TimelineContent>
          </TimelineItem>
          {renderLineItemsItinerary(lineItems)}
        </Timeline>
      </Box>
    );
  };

  const renderDescription = () => {
    return (
      <Box
        className={
          styles["experiences-shop-what-to-expect-description-container"]
        }
      >
        <Typography
          variant="body2"
          className={styles["experiences-shop-what-to-expect-description"]}
        >
          {!showDescription && description
            ? truncateString(description, 300, true)
            : description}
        </Typography>
        <Link
          component="button"
          onClick={() => setShowDescription(!showDescription)}
          className={
            styles["experiences-shop-what-to-expect-description-button"]
          }
        >
          {showDescription ? textConstants.READ_LESS : textConstants.READ_MORE}
        </Link>
      </Box>
    );
  };

  return (
    <Box className={styles["experiences-shop-what-to-expect-container"]}>
      {description && renderDescription()}
      {itinerary?.ExperienceItinerary ===
        ExperienceItineraryEnum.ExperienceItineraryLineItems &&
        renderLineItems((itinerary as ExperienceItineraryLineItems).lineItems)}
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );
};
