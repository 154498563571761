import React from "react";
import { DesktopHotelPhotoModalConnectorProps } from "./container";
import { Button } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  ActionButton,
  DesktopPopupModal,
  getImagesArray,
  HotelName,
  HotelStarRating,
  PhotoLayout,
  HotelTotalPanel,
  Icon,
  IconName,
  HotelPriceAndRewardsDisplay,
} from "halifax";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { BaseAddress, StayTypesEnum, LodgingCollectionEnum } from "redmond";
import { goToVacationRentalsCheckout } from "../../utils/queryStringHelpers";

export interface DesktopHotelPhotoModalProps
  extends DesktopHotelPhotoModalConnectorProps,
    RouteComponentProps {
  isSkeleton?: boolean;
  handleReadyToRedirect?: () => void;
}

export const DesktopHotelPhotoModal = (props: DesktopHotelPhotoModalProps) => {
  const {
    history,
    roomInfoProducts,
    lodging,
    roomProduct,
    nightCount,
    isSkeleton,
    rewardsKey,
    viewedPremierCollectionDetailsProperties,
    stayType,
    listing,
    shopDetailsLodgingData,
} = props;
  const [imagesArray, setImagesArray] = React.useState<string[]>([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    getImagesArray(
      shopDetailsLodgingData ? shopDetailsLodgingData.media :
      (stayType === StayTypesEnum.VacationRentals
        ? listing?.listing?.content?.media
        : lodging?.lodging.media) ?? [],
      setImagesArray
    );
  }, [lodging, setImagesArray, stayType, listing]);

  const filteredImagesArray = React.useMemo(
    () =>
      // The filter method is used to remove empty items in imagesArray
      imagesArray.filter((imageTuple) => !!imageTuple),
    [imagesArray]
  );

  return (
    <div className="desktop-pc-photo-modal">
      {isSkeleton && (
        <Skeleton className="desktop-pc-photo-modal-main-image-container skeleton" />
      )}
      {!isSkeleton && (
        <div
          className="desktop-pc-photo-modal-main-image-container"
          onClick={() => {
            {
              stayType === StayTypesEnum.Hotels &&
                trackEvent({
                  eventName: "hotel_shop_image_modal",
                  properties: {
                    ...viewedPremierCollectionDetailsProperties,
                    is_pc:
                      lodging?.lodgingCollection ===
                      LodgingCollectionEnum.Premier ||
                      shopDetailsLodgingData?.lodgingCollection ===
                      LodgingCollectionEnum.Premier,
                    is_lc:
                      lodging?.lodgingCollection ===
                      LodgingCollectionEnum.Lifestyle ||
                      shopDetailsLodgingData?.lodgingCollection ===
                      LodgingCollectionEnum.Lifestyle,
                  },
                });
            }
            setOpenModal(!openModal);
          }}
        >
          <div className="desktop-pc-photo-modal-main-image-section-left">
            <img
              className="main-image-left"
              src={filteredImagesArray[0]}
              alt="pc-1"
            />
          </div>
          <div className="desktop-pc-photo-modal-main-image-section-right">
            <div className="main-image-right top-image">
              <img src={filteredImagesArray[1]} alt="pc-2" />
            </div>
            <div className="main-image-right bottom-image">
              <img src={filteredImagesArray[2]} alt="pc-3" />
            </div>
          </div>
          <Button
            className="show-all-photos-button"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <Icon name={IconName.ShowAllImagesIcon} />
            <span className="show-all-photos-button-label">
              See all photos ({filteredImagesArray.length})
            </span>
          </Button>
        </div>
      )}
      {/*TODO handle lodging for dateless search and modal button when click on images. need to change BE response*/}
      <DesktopPopupModal
        className={clsx("desktop-pc-photo-modal-popup", "b2b")}
        open={openModal}
        invisibleBackdrop={false}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenModal(false);
        }}
      >
        <div
          id="desktop-pc-photo-modal-container"
          className="desktop-pc-photo-modal-container"
        >
          <div id="photo-gallery" className="photo-gallery">
            <PhotoLayout
              imgUrls={filteredImagesArray}
              scrollableTarget="photo-gallery"
            />
          </div>
          <div className="photo-gallery-pc-info">
            <HotelStarRating
              starRating={lodging?.lodging?.starRating || shopDetailsLodgingData?.starRating}
              city={
                lodging
                  ? lodging?.lodging?.city
                  : (listing?.listing?.content?.location.address as BaseAddress)
                      ?.city ||
                  (shopDetailsLodgingData?.location as BaseAddress)?.city
              }
              isSkeleton={isSkeleton}
            />
            <HotelName
              name={
                lodging
                  ? lodging?.lodging?.name
                  : listing?.listing?.content?.name ||
                  shopDetailsLodgingData?.name
              }
              isSkeleton={isSkeleton}
            />
            {!!roomProduct && !!lodging && (
              <HotelTotalPanel
                chosenRate={roomProduct}
                selectedLodging={lodging}
                cheapestProduct={roomInfoProducts[0]?.products[0]}
                nightCount={nightCount || undefined}
                rewardsKey={rewardsKey}
                useRedesign
              />
            )}
            {!!listing && (
              <HotelPriceAndRewardsDisplay
                lodgingPrice={listing?.availability.rate?.price}
                nightCount={nightCount ?? 0}
                isSkeleton={isSkeleton}
                rewardsKey={rewardsKey}
                variant={"default"}
              />
            )}
            <ActionButton
              className="desktop-photo-modal-choose-room-cta"
              onClick={() => {
                setOpenModal(false);
                switch (stayType) {
                  case StayTypesEnum.VacationRentals:
                    goToVacationRentalsCheckout({ history });
                    return;
                  case StayTypesEnum.Hotels:
                  default:
                    trackEvent({
                      eventName: "select_room_from_main_image",
                      properties: {
                        ...viewedPremierCollectionDetailsProperties,
                        is_pc:
                          lodging?.lodgingCollection ===
                          LodgingCollectionEnum.Premier ||
                          shopDetailsLodgingData?.lodgingCollection ===
                          LodgingCollectionEnum.Premier,
                        is_lc:
                          lodging?.lodgingCollection ===
                          LodgingCollectionEnum.Lifestyle ||
                          shopDetailsLodgingData?.lodgingCollection ===
                          LodgingCollectionEnum.Lifestyle,
                      },
                    });
                    setTimeout(() => {
                      // For /shop page
                      document
                        .getElementsByClassName("reserve-cta")[0]
                        ?.scrollIntoView({ behavior: "smooth", block: "end" });
                      // For /shop-details page
                      document
                        .getElementsByClassName("cta-select-dates-text")[0]
                        ?.scrollIntoView({ behavior: "smooth", block: "end" });
                    }, 300);
                    return;
                }
              }}
              message={
                <span className="button-text">
                  {stayType === StayTypesEnum.Hotels
                    ? "Choose Room"
                    : "Reserve Now"}
                </span>
              }
            />
          </div>
        </div>
      </DesktopPopupModal>
    </div>
  );
};
