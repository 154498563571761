import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import {
  useDeviceTypes,
} from "halifax";
import queryStringParser from "query-string";

import { PremierCollectionShopDetailsConnectorProps } from "./container";
import { DesktopShopDetails } from "./components/DesktopShopDetails";
import { MobileShop } from "../shop/components/MobileShop";
import {
  CallState,
  VIEWED_PC_DETAILS,
  LodgingCollectionEnum,
  VIEWED_LC_DETAILS,
  StayTypesEnum,
} from "redmond";
import { PORTAL_TITLE, VIEW_HOTEL_TITLE } from "./textConstants";
import { handlePageRedirectOnSelectRoomProduct } from "../shop/utils/queryStringHelpers";
import {
  CONTROL,
  getExperimentVariantCustomVariants,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  useExperiments,
} from "../../context/experiments";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import "./styles.scss";
import { PATH_ANCILLARY_CUSTOMIZE, PATH_BOOK } from "../../utils/paths";

export interface IHotelShopProps
  extends PremierCollectionShopDetailsConnectorProps,
    RouteComponentProps {}

export const PremierCollectionShopDetails = (props: IHotelShopProps) => {
  const {
    fetchPremierCollectionShop,
    history,
    viewedPremierCollectionDetailsProperties,
    trackingProps,
    fetchTravelWalletDetails,
    fetchedPremierCollectionDetails,
    fetchTravelWalletCreditHistory,
    selectedLodgingData,
    setStayType,
    listPaymentMethods,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const [isReadyToRedirect, setIsReadyToRedirect] = useState<boolean>(false);

  const expState = useExperiments();

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  const handleReadyToRedirect = () => {
    // note: it needs to wait for `selectRoomType` to be executed before proceeding with the page redirect
    setTimeout(() => {
      setIsReadyToRedirect(true);
    });
  };

  useEffect(() => {
    if (selectedLodgingData) {
      document.title = selectedLodgingData.name;
    }
  }, [selectedLodgingData]);

  useEffect(() => {
    fetchTravelWalletCreditHistory()
    fetchTravelWalletDetails();
    listPaymentMethods();
    setStayType(StayTypesEnum.Hotels);
    document.title = VIEW_HOTEL_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (fetchedPremierCollectionDetails) {
      trackEvent({
        eventName:
         selectedLodgingData?.lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? VIEWED_LC_DETAILS
          : VIEWED_PC_DETAILS,
        ...trackingProps,
        ...viewedPremierCollectionDetailsProperties,
      });
    }
  }, [fetchedPremierCollectionDetails]);

  useEffect(() => {
    if (
      [CallState.Success, CallState.Failed].includes(expState.callState) ||
      (expState.experiments.length > 0 &&
        (history.location.state === undefined ||
          history.location.state === null ||
          ![PATH_BOOK, PATH_ANCILLARY_CUSTOMIZE].includes(
            history.location.state["fromPage"]
          )))
    ) {
      fetchPremierCollectionShop(history, {
        overrideStateByQueryParams: true,
      });
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [expState.callState, queryString.lodgingId]);

  /*
    note: selectRoomType (the action that updates indexes in redux state) is called within a halifax component as a callback;
    it's executed at the same time as when `onClickContinue` is handled, which means that some states are not yet updated.
  */
  useEffect(() => {
    if (isReadyToRedirect) {
      handlePageRedirectOnSelectRoomProduct({
        history,
      });
      setIsReadyToRedirect(false);
    }
  }, [history, isReadyToRedirect]);

  return (
    <>
      <Box className={"premier-collection-shop-root"}>
        {matchesDesktop && (
          <DesktopShopDetails
            handleReadyToRedirect={handleReadyToRedirect}
            variant={
              (isLCForNonPremiumCardHoldersEnabled ||
                isLCForPremiumCardHoldersEnabled) &&
                selectedLodgingData?.lodgingCollection ===
                LodgingCollectionEnum.Lifestyle
                ? "lifestyle-collection"
                : "default"
            }
          />
        )}
        {matchesMobile && ( // TODO https://hopper-jira.atlassian.net/browse/CLUX-449
          <MobileShop
            handleReadyToRedirect={handleReadyToRedirect}
            variant={
            (isLCForNonPremiumCardHoldersEnabled ||
              isLCForPremiumCardHoldersEnabled) &&
              selectedLodgingData?.lodgingCollection ===
              LodgingCollectionEnum.Lifestyle
              ? "lifestyle-collection"
              : "default"
            }
          />
        )}
      </Box>
    </>
  );
};
