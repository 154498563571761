import { CallState, ExperienceId, ExperiencesShopResponse } from "redmond";
import { actions, actionTypes } from "../actions";

export interface IExperiencesShopState {
  selectedExperienceId?: ExperienceId;
  experiencesShopCallState: CallState;
  experiencesShopDetails?: ExperiencesShopResponse;
}

export const initialState: IExperiencesShopState = {
  experiencesShopCallState: CallState.NotCalled,
};

export const reducer = (
  state: IExperiencesShopState = initialState,
  action: actions.ExperiencesShopActions
): IExperiencesShopState => {
  switch (action.type) {
    case actionTypes.FETCH_EXPERIENCES_SHOP:
      return {
        ...state,
        experiencesShopCallState: CallState.InProcess,
        experiencesShopDetails: undefined,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_DETAILS:
      return {
        ...state,
        experiencesShopCallState: CallState.Success,
        experiencesShopDetails: action.response,
      };
    case actionTypes.SET_EXPERIENCES_SHOP_CALL_FAILED:
      return {
        ...state,
        experiencesShopCallState: CallState.Failed,
      };
    default:
      return {
        ...state,
      };
  }
};

export * from "./selectors";
