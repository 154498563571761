export const FETCH_PREMIER_COLLECTION_SHOP_DETAILS =
  "premierCollectionShopDetails/FETCH_PREMIER_COLLECTION_SHOP_DETAILS";
export type FETCH_PREMIER_COLLECTION_SHOP_DETAILS =
  typeof FETCH_PREMIER_COLLECTION_SHOP_DETAILS;

export const SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS =
  "premierCollectionShopDetails/SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS";
export type SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS =
  typeof SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS;

export const SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED =
  "premierCollectionShopDetails/SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED";
export type SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED =
  typeof SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED;

export const ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE =
  "premierCollectionShopDetails/ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE";
export type ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE =
  typeof ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE;

export const SELECT_ROOM_TYPE = "premierCollectionShopDetails/SELECT_ROOM_TYPE";
export type SELECT_ROOM_TYPE = typeof SELECT_ROOM_TYPE;

export const SELECT_LODGING = "premierCollectionShopDetails/SELECT_LODGING";
export type SELECT_LODGING = typeof SELECT_LODGING;
