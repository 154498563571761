import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";

// components
import { Box, IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import {
  ActionLink,
  CloseButtonIcon,
  CorpCompareBarBanner,
  CorpCompareBarTooltip,
  CorpPolicyBanner,
  GenericShopListFooter,
  HomeAvailabilityCard,
  HotelAvailabilityCard,
  HotelInformationalBanner,
  Icon,
  IconName,
  MobilePopoverCard,
  MultiroomSuffixText,
  OnSaleBanner,
  OutOfPolicyModal,
  PremierCollectionAvailabilityCard,
  PremierCollectionBenefitsModal,
  TravelSalesEventBanner,
  getIsFreeBreakfast,
  textConstants as halifaxTextConstants,
  useDeviceTypes,
  useForceUpdateAfterFirstRender,
} from "halifax";
import ReactList from "react-list";
import { DesktopCalendarPicker } from "../../../search/components/HotelSearchControl/components/DesktopCalendarPicker";
import { MobileCalendarPicker } from "../../../search/components/MobileHotelSearchControl/components";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";
import { AvailabilityNoResults } from "../AvailabilityNoResults";
import { AvailabilitySearchControl } from "../AvailabilitySearchControl";
import { CorporateDebuggingPanel } from "../CorporateDebuggingPanel";

// types, constants
import {
  CorpLodging,
  CorpLodgingData,
  HotelStarRatingEnum,
  IIdLodgings,
  Lodging,
  LodgingCollectionEnum,
  LodgingSelectionEnum,
  ModalScreens,
  POLICY_DESCRIPTOR,
  PolicyDescriptorEntryPoints,
  SELECTED_HOTEL_FROM_LIST,
  SELECTED_TRAVEL_OFFER,
  SELECTED_UNAVAILABLE_HOTEL_FROM_LIST,
  SelectedTravelOfferScreen,
  VIEWED_OFFER_FAQ,
  VIEWED_POLICY_DESCRIPTOR,
  VIEWED_UNAVAILABLE_HOTEL_LIST,
  getLodgingTrackingProperties,
  ViewedCorpRateDescriptorEntryPoints,
  ENGAGED_OFFER_CTA,
  nthNightPromotion,
} from "redmond";
import * as textConstants from "./textConstants";

// helpers, utils
import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCaponeTenant,
  isCorpTenant,
} from "@capone/common";
import clsx from "clsx";
import dayjs from "dayjs";
import queryStringParser from "query-string";
import { InView } from "react-intersection-observer";
import { RouteComponentProps } from "react-router";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  PATH_AVAILABILITY,
  PATH_HOME,
  PATH_HOME_STAYS,
  PATH_SHOP,
  PATH_TRAVEL_SALE,
  PATH_VACATION_RENTAL_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
} from "../../../../utils/paths";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedQuery,
} from "../../../shop/utils/queryStringHelpers";
import { HotelAvailabilityCallState } from "../../reducer/state";
import { AvailabilityListConnectorProps } from "./container";

// experiments
import { useExperimentIsVariant } from "@capone/experiments";
import {
  HotelDetailsEntrySourceEnum,
  LodgingPromotionType,
  StayType,
} from "redmond/hotels-module/interfaces";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import {
  AVAILABLE,
  CONTROL,
  CORP_DEBUGGING_PANEL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  DESKTOP_RECENTLY_VIEWED_HOTELS,
  DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS,
  FREE_BREAKFAST_CANCEL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS,
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  STAYS_SEARCH,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { PriceDropProtectionBanner } from "../../../ancillary/components/addOnComponents";
import {
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
  TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
} from "../../textConstants";
import { AvailabilitySort } from "../AvailabilitySort";
import { isLodgingRecommendedBasedOnPreferences } from "./utils";
import { onOpenCompareBarTooltip } from "../../../../utils/events";

const APPROXIMATE_HOTEL_AVAILABILITY_CARD_HEIGHT = 284;

export interface IAvailabilityListProps
  extends AvailabilityListConnectorProps,
    RouteComponentProps {
  setOpenPriceDropProtectionBannerModal: (arg: boolean) => void;
}

export const AvailabilityList = (props: IAvailabilityListProps) => {
  const {
    lodgings,
    setLodgingIdInFocus,
    setLodgingIdHovered,
    fetchMoreHotelAvailability,
    setOpenDatesModal,
    nightCount,
    searchedLocation,
    hotelAvailabilityCallState,
    accountReferenceId,
    hotelQueryParams,
    history,
    viewedHotelListProperties,
    isFilteredHotelAvailabilityLodgingsEmpty,
    hasViewedUnavailableHotel,
    setHasViewedUnavailableHotel,
    fromDate,
    untilDate,
    adultsCount,
    children,
    mapBounds,
    setMapBound,
    openDatesModal,
    setSelectedLodgingIndex,
    roomsCount,
    searchedRoomsCount,
    searchedPetsCount,
    credit,
    isVentureX,
    selectedAccount,
    largestValueAccount,
    setViewHotelsNearLocation,
    isSearchingMap,
    filteredHotelAvailabilityLodgingsContainPremierCollection,
    setOpenPriceDropProtectionBannerModal,
    shouldApplyUserHotelPreferences,
    userHasSetHotelPreferences,
    userHotelPreferences,
    canEarnRewards,
    setIsPremierCollectionEnabled,
    showPremiumStaysOnly,
    resetFilters,
    bestOverallOffer,
  } = props;

  const [locationName, setLocationName] = React.useState("");

  const listRef = useRef<ReactList | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [showPolicyModal, setShowPolicyModal] = useState<string | null>(null);
  const [corpPolicyDescriptorViewCount, setCorpPolicyDescriptorViewCount] =
    useState(0);

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const outOfPolicyCopy =
    isAutoApprovalEnabled || isApprovalsV2Enabled
      ? "If you wish to proceed with your selection, admins will be notified upon booking that this hotel was out of policy."
      : "This hotel rate is out of your company policy. You can continue anyway or change your selection.";

  const expState = useExperiments();

  const isCorporateDebuggingPanelEnabled =
    getExperimentVariant(expState.experiments, CORP_DEBUGGING_PANEL) ===
    AVAILABLE;

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const freeBreakfastCancel = getExperimentVariant(
    expState.experiments,
    FREE_BREAKFAST_CANCEL
  );
  const isFreeBreakfastCancelExperiment = useMemo(
    () => freeBreakfastCancel === AVAILABLE,
    [freeBreakfastCancel]
  );

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = React.useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isRecentlyViewedHotelsDesktopExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      DESKTOP_RECENTLY_VIEWED_HOTELS,
      DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS
    ) !== CONTROL;

  const isRecentlyViewedHotelsMobileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      MOBILE_RECENTLY_VIEWED_HOTELS,
      MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
    ) !== CONTROL;

  const LCForPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForPremiumCardHoldersEnabled =
    LCForPremiumCardholderVariant !== CONTROL;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.hotelsMultiplier &&
    canEarnRewards;

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const globalMobileNav = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );

  const isGlobalMobileNavExperiment = useMemo(() => {
    return globalMobileNav === AVAILABLE;
  }, [globalMobileNav]);

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : isApprovalsV2Enabled
    ? "out_of_policy_24hr_review"
    : "out_of_policy";

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const [untilProp, setUntilProp] = useState<Date | null>(null);
  const [fromProp, setFromProp] = useState<Date | null>(null);
  const [refetchFromCalendarModal, setRefetchFromCalendarModal] =
    useState(false);
  const [openBenefitsModal, setOpenBenefitsModal] = useState(false);
  const [isSearchTermLodging, setIsSearchTermLodging] = React.useState(false);
  // If search term is location (ex: Toronto) vs if search term is point of interest (ex: Toronto Zoo)
  const [isSearchTermPoint, setIsSearchTermPoint] = React.useState(false);
  const [benefitsModalVariant, setBenefitsModalVariant] = React.useState<
    | "premier-collection"
    | "lifestyle-collection-premium"
    | "lifestyle-collection-non-premium"
  >();

  // Hack for render multiple ReactLists properly. In the first rendering, the second list does not get the correct height of the first list.
  // Forcing a render right after the initial render makes the second list position properly.
  useForceUpdateAfterFirstRender();

  useEffect(() => {
    setIsPremierCollectionEnabled(isPremierCollectionEnabled);
  }, [isPremierCollectionEnabled]);

  useEffect(() => {
    const [locationName] = searchedLocation?.label
      ? searchedLocation.label.split(",")
      : [];
    setLocationName(locationName);

    const placeTypes = searchedLocation
      ? (searchedLocation.id as IIdLodgings).lodgingSelection.placeTypes
      : [];

    setIsSearchTermLodging(placeTypes.includes("lodging"));
    setIsSearchTermPoint(
      !placeTypes.includes("locality") &&
        !placeTypes.includes("political") &&
        !placeTypes.includes("country")
    );
  }, [searchedLocation]);

  useEffect(() => {
    const parsedQueryStringPrimitive = queryStringParser.parse(
      history.location.search
    );
    setUntilProp(
      dayjs(parsedQueryStringPrimitive.untilDate as string).toDate()
    );
    setFromProp(dayjs(parsedQueryStringPrimitive.fromDate as string).toDate());
  }, [history.location.search]);

  useEffect(() => {
    if (location && fromDate && untilDate && refetchFromCalendarModal) {
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          (searchedLocation?.id as IIdLodgings).lodgingSelection.searchTerm,
          fromDate,
          untilDate,
          adultsCount,
          children,
          roomsCount,
          searchedPetsCount,
          undefined
        )}`
      );

      mapBounds && setMapBound(null);
      setViewHotelsNearLocation(null);
      setRefetchFromCalendarModal(false);
    }
  }, [fromDate, untilDate]);

  const presentHotelInfoBanner = (nthNightPromotion?: nthNightPromotion) => {
    if(nthNightPromotion) {
      return (
        <HotelInformationalBanner
        content={
          <Tooltip
            title={
              <Typography className="tooltip-text">
                {nthNightPromotion?.tooltipDescription}
              </Typography>
            }
            classes={{
              popper: "nth-night-tooltip-popper",
              tooltip: "nth-night-tooltip-text",
            }}
            disableFocusListener={false}
            aria-labelledby={nthNightPromotion?.tooltipDescription}
          >
            <Box className="nth-night-banner-content">
              <Typography className="nth-night-content-text">
                {nthNightPromotion?.description}
              </Typography>
              <IconButton aria-labelledby="nth-night-info-label">
                <Icon name={IconName.InfoCircle} />
              </IconButton>
            </Box>
          </Tooltip>
        }
        isMobile={matchesMobile}
        isMap={false}
        iconName={IconName.Sparkle}
      />
      )
    } else {
      return (
        <HotelInformationalBanner
              content={
                <Tooltip
                  title={
                    <Typography className="tooltip-text">
                      {halifaxTextConstants.FREE_BREAKFAST_TOOLTIP}
                    </Typography>
                  }
                  classes={{
                    popper: "free-breakfast-tooltip-popper",
                    tooltip: "free-breakfast-tooltip-text",
                  }}
                >
                  <Box className="free-breakfast-banner-content">
                    <Typography className="free-breakfast-content-text">
                      Free breakfast for cardholders
                    </Typography>
                    <Icon name={IconName.InfoCircle} />
                  </Box>
                </Tooltip>
              }
              isMobile={matchesMobile}
              isMap={false}
              iconName={IconName.FreeBreakfast}
            />
      )
    }
  }

  const isAvail = (curr: Lodging) =>
    typeof curr.available === "undefined" || curr.available;

  const sortedLodgings = useMemo(() => {
    // SORT
    const searchedLodgingSelection = (searchedLocation?.id as IIdLodgings)
      ?.lodgingSelection;

    if (
      searchedLodgingSelection &&
      searchedLodgingSelection.placeTypes.includes("lodging")
    ) {
      const searchTermName = searchedLodgingSelection.searchTerm.split(",")[0];
      return lodgings
        .slice()
        .sort(
          (a, b) =>
            b.lodging.name.toLowerCase().indexOf(searchTermName.toLowerCase()) -
            a.lodging.name.toLowerCase().indexOf(searchTermName.toLowerCase())
        );
    }
    return lodgings;
  }, [lodgings, searchedLocation]);

  const renderEachLodging = (
    lodgingData: Lodging | CorpLodging,
    index: number
  ) => {
    const {
      isPreferred,
      isFreeCancel,
      lodging,
      price,
      bestPromotionThisLodging,
      lodgingCollection,
      bestOfferThisLodging,
      stayType,
      nthNightPromotion,
    } = lodgingData;
    const ventureXGating = isVentureX
      ? lodging.starRating === HotelStarRatingEnum.Four ||
        lodging.starRating === HotelStarRatingEnum.Five
      : true;
    const nonVentureXGating = !isVentureX
      ? [
          HotelStarRatingEnum.Three,
          HotelStarRatingEnum.Four,
          HotelStarRatingEnum.Five,
        ].includes(lodging.starRating)
      : true;
    const showOnSaleBanner =
      isLodgingPromotionsExperiment &&
      bestPromotionThisLodging &&
      !!bestPromotionThisLodging.discountPercentage &&
      bestPromotionThisLodging.discountPercentage > 0 &&
      bestPromotionThisLodging.discountAmount.amount > 5 &&
      bestPromotionThisLodging.promotionType ==
        LodgingPromotionType.DealOfTheDay &&
      ventureXGating &&
      nonVentureXGating &&
      lodgingData.lodging.tripAdvisorReviews &&
      lodgingData.lodging.tripAdvisorReviews.overallScore * 5 >= 3.5;

    const available =
      typeof lodgingData.available === "undefined"
        ? true
        : lodgingData.available;

    const showPremierCollection =
      (isPremierCollectionEnabled &&
        lodgingCollection === LodgingCollectionEnum.Premier) ||
      ((isLCForPremiumCardHoldersEnabled ||
        isLCForNonPremiumCardHoldersEnabled) &&
        lodgingCollection === LodgingCollectionEnum.Lifestyle);

    const isHome = stayType === StayType.Homes;

    const containsFreeBreakfastOrNthNight =
       !matchesMobile && isCaponeTenant(config.TENANT) &&
       showPremierCollection &&
       (getIsFreeBreakfast(lodging.amenities, true) ||
         nthNightPromotion);

    if (!available && !hasViewedUnavailableHotel) {
      setHasViewedUnavailableHotel();
      trackEvent({
        eventName: VIEWED_UNAVAILABLE_HOTEL_LIST,
        ...viewedHotelListProperties,
      });
    }

    const hasPolicyCompliance = "corporateTravel" in lodging;
    const isInPolicy =
      hasPolicyCompliance &&
      (lodging.corporateTravel.policyCompliance.isInPolicy ?? true);
    const policyReasons = hasPolicyCompliance
      ? lodging.corporateTravel.policyCompliance.reasons
      : [];

    const onOpenPolicyDescriptor = () => {
      if (corpPolicyDescriptorViewCount <= 5) {
        trackEvent({
          eventName: VIEWED_POLICY_DESCRIPTOR,
          properties: {
            type: POLICY_DESCRIPTOR,
            entry_point: PolicyDescriptorEntryPoints.HOTELS_LIST,
            funnel: "hotels",
            policy_reason: policyReasons.join(", "),
          },
        });
        setCorpPolicyDescriptorViewCount((prevState) => prevState + 1);
      }
    };

    const lodgingRecommendedBasedOnPreferences =
      isCustomerProfileExperiment &&
      !shouldApplyUserHotelPreferences &&
      userHasSetHotelPreferences &&
      !!userHotelPreferences &&
      isLodgingRecommendedBasedOnPreferences(lodgingData, userHotelPreferences);

    const showCorpCompareBarBanner =
      matchesDesktop && Boolean(lodgingData.hasCorporateRates);

    return (
      <Link
        id={`availability-row-${index}`}
        className={clsx("availability-row", {
          unavailable: !available,
          "nav-improvement": isGlobalMobileNavExperiment,
        })}
        component="button"
        key={lodging.id}
        onClick={() => {
          if (available) {
            trackEvent({
              eventName: SELECTED_HOTEL_FROM_LIST,
              properties: {
                ...viewedHotelListProperties.properties,
                ...lodgingData?.trackingPropertiesV2?.properties,
                is_preferred_cot: isPreferred,
                lodging_row_index: index,
                ...getLodgingTrackingProperties(lodgingData).properties,
              },
              encryptedProperties: [
                ...viewedHotelListProperties.encryptedProperties,
                ...getLodgingTrackingProperties(lodgingData)
                  .encryptedProperties,
                lodgingData?.trackingPropertiesV2?.encryptedProperties ?? "",
              ],
            });
            setSelectedLodgingIndex(index);

            let params = transformToStringifiedQuery({
              lodgingId: lodging.id,
              lodgingSelection: mapBounds
                ? {
                    LodgingSelection: LodgingSelectionEnum.Location,
                    descriptor: mapBounds,
                  }
                : (searchedLocation?.id as IIdLodgings).lodgingSelection,
              ...hotelQueryParams,
              selectedLodgingIndex: index,
              roomsCount: searchedRoomsCount,
              petsCount: searchedPetsCount,
              fromHotelAvailability:
                lodgingCollection === LodgingCollectionEnum.Premier ||
                lodgingCollection === LodgingCollectionEnum.Lifestyle
                  ? true
                  : undefined,
              recommendedBasedOnPreferences:
                lodgingRecommendedBasedOnPreferences,
              hotelDetailsEntrySource: HotelDetailsEntrySourceEnum.LIST,
            });

            if (isHome && staysSearchEnabled) {
              // Vacation Rentals (Homes) uses listingId instead of lodgingId in the shop URL
              params = params.replace("lodgingId", "listingId");
            }

            const path =
              isHome && staysSearchEnabled
                ? PATH_VACATION_RENTAL_SHOP
                : lodgingCollection === LodgingCollectionEnum.Premier ||
                  lodgingCollection === LodgingCollectionEnum.Lifestyle
                ? PREMIER_COLLECTION_PATH_SHOP
                : PATH_SHOP;

            if (hasPolicyCompliance && !isInPolicy) {
              trackEvent(
                getShowOOPModalEvent(
                  ModalScreens.HOTELS_AVAILABILITY,
                  "hotels",
                  modalType
                )
              );
              return setShowPolicyModal(`${path}${params}`);
            }
            if (matchesMobile)
              history.push(`${path}${params}`, { fromPage: location.pathname });
            else window.open(`${path}${params}`, "_blank");
          } else {
            trackEvent({
              eventName: SELECTED_UNAVAILABLE_HOTEL_FROM_LIST,
              properties: {
                ...viewedHotelListProperties.properties,
                lodging_row_index: index,
              },
              encryptedProperties:
                viewedHotelListProperties.encryptedProperties,
            });
          }
        }}
        onMouseEnter={() => {
          setLodgingIdHovered(lodging.id);
        }}
        onMouseLeave={() => setLodgingIdHovered(null)}
        onFocus={(event: any) => {
          if (
            event.target == document.getElementById(`availability-row-${index}`)
          )
            if (available) setLodgingIdInFocus(lodging.id);
        }}
      >
        <InView
          as="div"
          onChange={(inView) => {
            if (inView) {
              const nightlyTotalWithTaxesAndFees =
                lodgingData.price?.breakdown &&
                "taxesAndFees" in lodgingData.price?.breakdown
                  ? (lodgingData.price?.nightlyPrice.fiat.value || 0) +
                    (lodgingData.price?.breakdown.taxesAndFees?.fiat.value ||
                      0) /
                      (nightCount || 1)
                  : lodgingData.price?.nightlyPrice.fiat.value || 0;

              const searchedLodgingSelection = (
                searchedLocation?.id as IIdLodgings
              )?.lodgingSelection;

              let isSearchedLodging = false;

              if (
                searchedLodgingSelection &&
                searchedLodgingSelection.placeTypes.includes("lodging")
              ) {
                const searchTermName =
                  searchedLodgingSelection.searchTerm.split(",")[0];

                isSearchedLodging =
                  lodgingData.lodging.name.startsWith(searchTermName);
              }

              trackEvent({
                eventName: "viewed_lodging_in_list",
                properties: {
                  ...viewedHotelListProperties.properties,
                  ...getLodgingTrackingProperties(lodgingData).properties,
                  lodging_row_index: index,
                  lodging_id: lodging.id,
                  lodging_name: lodging.name,
                  lodging_tripadvisor_rating:
                    lodging.tripAdvisorReviews?.overallScore.toString(),
                  promotion_applicable:
                    lodgingData?.bestPromotionThisLodging?.promotionType,
                  is_preferred: lodgingData?.isPreferred,

                  is_available: lodgingData?.available,
                  google_place_id: searchedLodgingSelection?.placeId,
                  daily_owed_including_taxes_fees_usd:
                    nightlyTotalWithTaxesAndFees,
                  daily_owed_excluding_taxes_fees_usd:
                    lodgingData.price?.nightlyPrice.fiat.value,
                  is_compare_to_bar: showCorpCompareBarBanner,
                  is_searched: isSearchedLodging,
                  is_vr: isHome,
                },
                encryptedProperties: [
                  ...viewedHotelListProperties.encryptedProperties,
                  ...getLodgingTrackingProperties(lodgingData).encryptedProperties,
                ],
              });
            }
          }}
          threshold={0.8}
        >
          { containsFreeBreakfastOrNthNight ? (
           presentHotelInfoBanner(nthNightPromotion)
          ) : !matchesMobile && showOnSaleBanner ? (
            <OnSaleBanner
              salePercentage={
                bestPromotionThisLodging!.discountPercentage! * 100
              }
            />
          ) : null}
          <Box
            className={clsx("hotel-availability-card-wrapper", {
              unavailable: !available,
              "on-sale": showOnSaleBanner,
              "premier-collection": showPremierCollection,
              "capone-corporate": isCorpTenant(config.TENANT),
              "in-policy": isInPolicy,
              "free-breakfast":
                isCaponeTenant(config.TENANT) &&
                lodgingCollection === LodgingCollectionEnum.Lifestyle &&
                getIsFreeBreakfast(lodging.amenities, true),
                "nth-night": nthNightPromotion,
            })}
          >
            <CorpPolicyBanner
              variant={matchesDesktop ? "descriptor" : "base"}
              productType="hotel"
              corporateTravel={(lodging as CorpLodgingData)?.corporateTravel}
              limit={policies?.hotels.policies[0].maxPricePerNight}
              onOpen={() => onOpenPolicyDescriptor()}
              {...(matchesMobile && { borderRadius: 0 })}
            />
            <CorpCompareBarBanner
              show={showCorpCompareBarBanner}
              onOpen={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_CARD
              )}
            />
            <Box className="hotel-availability-card-content">
              {isHome ? (
                <HomeAvailabilityCard
                  isMobile={matchesMobile}
                  isGlobalMobileNavExperiment={isGlobalMobileNavExperiment}
                  lodging={lodgingData}
                  nightCount={nightCount}
                  rewardsKey={accountReferenceId || undefined}
                  earnTagContent={
                    showEarnEnhancement ? (
                      <>
                        <Icon name={IconName.StarIcon} />
                        <Typography
                          className="earn-tag-text"
                          dangerouslySetInnerHTML={{
                            __html: textConstants.getEarnTagText(
                              largestValueAccount.earn.hotelsMultiplier, // homes within stays will use the hotels multiplier
                              largestValueAccount.rewardsBalance
                                .currencyDescription ??
                                largestValueAccount.rewardsBalance.currency,
                              true
                            ),
                          }}
                        />
                      </>
                    ) : undefined
                  }
                />
              ) : showPremierCollection ? (
                <PremierCollectionAvailabilityCard
                  hotelAvailabilityInfo={{
                    nthNightPromotion,
                    bestOfferThisLodging,
                    bestPromotionThisLodging,
                    lodging,
                    price,
                    isFreeCancel,
                  }}
                  nightCount={nightCount}
                  rewardsKey={accountReferenceId || undefined}
                  available={available}
                  isLifestyleCollection={
                    (isLCForPremiumCardHoldersEnabled ||
                      isLCForNonPremiumCardHoldersEnabled) &&
                    lodgingCollection === LodgingCollectionEnum.Lifestyle
                  }
                  showRibbon
                  showHotelDetailsCTA={false}
                  isMobile={matchesMobile}
                  onBenefitsTooltipClick={() => {
                    setBenefitsModalVariant(
                      (() => {
                        if (
                          lodgingCollection === LodgingCollectionEnum.Lifestyle
                        ) {
                          if (isLCForPremiumCardHoldersEnabled)
                            return "lifestyle-collection-premium";
                          return "lifestyle-collection-non-premium";
                        }
                        return "premier-collection";
                      })()
                    );
                    setOpenBenefitsModal(true);
                  }}
                  showOffer={
                    isTravelWalletOfferExperiment &&
                    lodgingData.bestOfferThisLodging?.amount.amount !==
                      credit?.amount.amount
                  }
                  className={
                    containsFreeBreakfastOrNthNight
                    ? (nthNightPromotion ? "nth-night" : "free-breakfast")
                      : undefined
                  }
                  earnTagContent={
                    showEarnEnhancement ? (
                      <>
                        <Icon name={IconName.StarIcon} />
                        <Typography
                          className="earn-tag-text"
                          dangerouslySetInnerHTML={{
                            __html: textConstants.getEarnTagText(
                              largestValueAccount.earn.hotelsMultiplier,
                              largestValueAccount.rewardsBalance
                                .currencyDescription ??
                                largestValueAccount.rewardsBalance.currency
                            ),
                          }}
                        />
                      </>
                    ) : undefined
                  }
                  earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
                  tooltipContent={
                    <>
                      <Box className="benefits-summary">
                        <Icon name={IconName.StarOutline} />
                        <Typography className="benefits-summary-text">
                          {textConstants.getBenefitsSummary(
                            lodgingCollection,
                            selectedAccount?.productDisplayName,
                            largestValueAccount.earn?.hotelsMultiplier,
                            selectedAccount?.customerAccountRole === "Primary"
                          )}
                        </Typography>
                      </Box>
                      <Box className="additional-benefits">
                        <Icon name={IconName.GiftOutline} />
                        <Typography className="additional-benefits-summary-text">
                          {textConstants.getAdditionalBenefitsSummary()}
                        </Typography>{" "}
                      </Box>
                    </>
                  }
                  tenant={config.TENANT}
                  onOfferTooltipSetOpenModal={(open) => {
                    if (open) {
                      trackEvent({
                        eventName: ENGAGED_OFFER_CTA,
                        properties: {
                          location: SelectedTravelOfferScreen.HOTEL_SHOP,
                          entry_type: "tooltip",
                          funnel:
                            lodgingData.bestOfferThisLodging?.funnels.join(","),
                          offer_name:
                            lodgingData.bestOfferThisLodging
                              ?.trackingPropertiesV2?.properties?.offer_name,
                        },
                        encryptedProperties: [
                          lodgingData.bestOfferThisLodging?.trackingPropertiesV2
                            ?.encryptedProperties ?? "",
                        ],
                      });
                    }
                  }}
                />
              ) : (
                <HotelAvailabilityCard
                  hotelAvailabilityInfo={{
                    nthNightPromotion,
                    bestPromotionThisLodging,
                    lodging,
                    price,
                    nightCount,
                    // TODO: ask about where to get city name only (e.g.: Toronto)
                    neighbourhood: searchedLocation?.label,
                    rewardsKey: accountReferenceId || undefined,
                    available: lodgingData.available,
                    bestOfferThisLodging: lodgingData.bestOfferThisLodging,
                    lastViewed:
                      (!matchesMobile &&
                        isRecentlyViewedHotelsDesktopExperiment) ||
                      (matchesMobile && isRecentlyViewedHotelsMobileExperiment)
                        ? lodgingData.lastViewed
                        : undefined,
                    searchedRoomCount: searchedRoomsCount,
                  }}
                  isLoyaltyEligible={Boolean(lodgingData.loyaltyProgramCode)}
                  hasCorporateRates={Boolean(lodgingData.hasCorporateRates)}
                  isMobile={matchesMobile}
                  isFreeCancel={isFreeCancel}
                  showOnSaleBanner={showOnSaleBanner}
                  onSaleAmount={
                    isLodgingPromotionsExperiment &&
                    bestPromotionThisLodging?.discountPercentage
                      ? bestPromotionThisLodging?.discountPercentage * 100
                      : undefined
                  }
                  isFreeBreakfastCancelExperiment={
                    isFreeBreakfastCancelExperiment
                  }
                  className="b2b"
                  showOffer={
                    isTravelWalletOfferExperiment &&
                    lodgingData.bestOfferThisLodging?.amount.amount !==
                      credit?.amount.amount
                  }
                  onOfferTooltipSetOpenModal={(open) => {
                    if (open) {
                      trackEvent({
                        eventName: SELECTED_TRAVEL_OFFER,
                        properties: {
                          screen: SelectedTravelOfferScreen.HOTEL_SHOP,
                          ...lodgingData.bestOfferThisLodging
                            ?.trackingPropertiesV2?.properties,
                        },
                        encryptedProperties: [
                          lodgingData.bestOfferThisLodging?.trackingPropertiesV2
                            ?.encryptedProperties ?? "",
                        ],
                      });

                      trackEvent({
                        eventName: ENGAGED_OFFER_CTA,
                        properties: {
                          location: SelectedTravelOfferScreen.HOTEL_SHOP,
                          entry_type: "tooltip",
                          funnel:
                            lodgingData.bestOfferThisLodging?.funnels.join(","),
                          offer_name:
                            lodgingData.bestOfferThisLodging
                              ?.trackingPropertiesV2?.properties?.offer_name,
                        },
                        encryptedProperties: [
                          lodgingData.bestOfferThisLodging?.trackingPropertiesV2
                            ?.encryptedProperties ?? "",
                        ],
                      });
                    }
                  }}
                  onFirstTravelOfferCardChange={() => {
                    trackEvent({
                      eventName: VIEWED_OFFER_FAQ,
                      properties: {
                        screen: SelectedTravelOfferScreen.HOTEL_SHOP,
                      },
                    });
                  }}
                  suffixText={
                    <MultiroomSuffixText
                      roomsCount={searchedRoomsCount}
                      className="availability-suffix-text"
                    />
                  }
                  earnTagContent={
                    showEarnEnhancement ? (
                      <>
                        <Icon name={IconName.StarIcon} />
                        <Typography
                          className="earn-tag-text"
                          dangerouslySetInnerHTML={{
                            __html: textConstants.getEarnTagText(
                              largestValueAccount.earn.hotelsMultiplier,
                              largestValueAccount.rewardsBalance
                                .currencyDescription ??
                                largestValueAccount.rewardsBalance.currency
                            ),
                          }}
                        />
                      </>
                    ) : undefined
                  }
                  earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
                  isTravelSale={travelSalesEventVariant === TRAVEL_SALE_ACTIVE}
                  isRecommendedBasedOnPreferences={
                    lodgingRecommendedBasedOnPreferences
                  }
                  isGlobalMobileNavExperiment={isGlobalMobileNavExperiment}
                  tenant={config.TENANT}
                />
              )}
            </Box>
          </Box>
        </InView>
      </Link>
    );
  };

  // note: the behaviour of having the car availability screen continuously making followUp requests is actually preferred;
  // please see the comment section on https://hopper-jira.atlassian.net/browse/BP-1519 for more info.
  useEffect(() => {
    if (
      hotelAvailabilityCallState ===
        HotelAvailabilityCallState.FollowUpSearchCallSuccess ||
      hotelAvailabilityCallState ===
        HotelAvailabilityCallState.InitialSearchCallSuccess
    ) {
      let shouldIncludeHomes = false;
      if (staysSearchEnabled) {
        shouldIncludeHomes = true;
      }
      setTimeout(
        () => fetchMoreHotelAvailability(history, shouldIncludeHomes),
        100
      );
    }
  }, [hotelAvailabilityCallState, fetchMoreHotelAvailability, history]);

  const [showWalletBanner, setShowWalletBanner] = useState(false);

  useEffect(() => {
    setShowWalletBanner(
      (credit && credit.amount.amount < 0) || !!bestOverallOffer || false
    );
  }, [credit, bestOverallOffer]);

  const PriceDropProtectionBannerWrapper = () => {
    // Only show banner once results are loaded
    return hotelAvailabilityCallState !=
      HotelAvailabilityCallState.InitialMapSearchCallInProcess &&
      hotelAvailabilityCallState != HotelAvailabilityCallState.NotCalled &&
      hotelAvailabilityCallState != HotelAvailabilityCallState.Failed &&
      hotelAvailabilityCallState !=
        HotelAvailabilityCallState.InitialSearchCallInProcess ? (
      <PriceDropProtectionBanner
        bannerVersion={"large"}
        showPremierCollectionText={
          filteredHotelAvailabilityLodgingsContainPremierCollection
        }
        setOpenModal={setOpenPriceDropProtectionBannerModal}
      />
    ) : null;
  };

  // We will show price drop protection banner once every 5 lodgings, as that is approximately one page
  // on a typical user screen.
  const LODGING_COUNT_BETWEEN_PRICE_DROP_BANNER = 5;
  const containerRef = useRef<null | HTMLElement>(null);

  return (
    <Box
      className={clsx("availability-list-root", {
        mobile: matchesMobile,
      })}
      {...{ ref: containerRef }}
    >
      {isCorporateDebuggingPanelEnabled && (
        <CorporateDebuggingPanel lodgings={lodgings} />
      )}

      <PremierCollectionBenefitsModal
        openBenefitsModal={openBenefitsModal}
        handleClosePCBenefitsModal={() => {
          setOpenBenefitsModal(false);
        }}
        isMobile={matchesMobile}
        variant={benefitsModalVariant}
        useBenefitsSummary
        isUserPrimary={selectedAccount?.customerAccountRole === "Primary"}
        tenant={config.TENANT}
        largestValueAccount={largestValueAccount}
      />
      {showPolicyModal && (
        <OutOfPolicyModal
          subtitle={outOfPolicyCopy}
          isOpen={!!showPolicyModal}
          isMobile={matchesMobile}
          onClose={() => {
            setShowPolicyModal(null);
            trackEvent(
              getClickCancelOOPModalEvent(
                ModalScreens.HOTELS_AVAILABILITY,
                "hotels",
                modalType
              )
            );
          }}
          onContinue={() => {
            if (matchesMobile) {
              history.push(showPolicyModal, { fromPage: location.pathname });
            } else {
              window.open(showPolicyModal, "_blank");
            }
            setShowPolicyModal(null);
            trackEvent(
              getClickContinueOOPModalEvent(
                ModalScreens.HOTELS_AVAILABILITY,
                "hotels",
                modalType
              )
            );
          }}
          isApprovalRequired={
            policies?.settings && policies.settings.isApprovalRequired
          }
        />
      )}
      <Box className="availability-list-container">
        {showWalletBanner && (
          <TravelWalletDetailsBanner
            showButton={!matchesMobile}
            screen={SelectedTravelOfferScreen.HOTEL_SHOP}
            {...props}
            offer={bestOverallOffer}
            bannerTextType="availability"
            onDismiss={
              matchesMobile ? undefined : () => setShowWalletBanner(false)
            }
          />
        )}
        {!matchesMobile && <AvailabilitySearchControl />}

        {isCorpTenant(config.TENANT) && (
          <Box
            className={clsx("availability-list-corp-bar-banner", {
              mobile: matchesMobile,
            })}
          >
            <Icon name={IconName.TagIcon} />
            <CorpCompareBarTooltip
              label={
                <Typography>
                  {textConstants.CORP_COMPARE_TO_BAR_BANNER_TEXT}
                  {matchesMobile && <InfoOutlined fontSize="small" />}
                </Typography>
              }
              additionalTooltipContent={
                <Typography style={{ fontStyle: "italic", marginTop: "10px" }}>
                  Look for the price tag icon to find hotels with these rates!
                </Typography>
              }
              onOpen={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_HEADER
              )}
              hideIcon={matchesMobile}
            />
          </Box>
        )}

        {!matchesMobile && (
          <Box className="availability-list-heading-sort">
            {locationName || isSearchingMap ? (
              <Typography
                variant="h3"
                className="availability-list-count-heading"
              >
                {textConstants.COUNT_HEADING_TEXT(
                  sortedLodgings.filter(isAvail).length,
                  locationName,
                  isSearchTermLodging,
                  isSearchTermPoint,
                  isSearchingMap,
                  staysSearchEnabled
                )}
              </Typography>
            ) : (
              <div />
            )}
            <AvailabilitySort />
          </Box>
        )}

        {!matchesMobile && travelSalesEventVariant === TRAVEL_SALE_ACTIVE && (
          <TravelSalesEventBanner
            variant="default"
            onClick={() => {
              const path = `${PATH_TRAVEL_SALE}?entryType=hotel_list_component`;
              window.open(path, "_blank");
            }}
            subtitle={TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE}
            buttonText={TRAVEL_SALES_EVENT_ACTIVE_CTA}
          />
        )}
        {isFilteredHotelAvailabilityLodgingsEmpty ? (
          <Box className="no-hotels-found-components-section">
            <AvailabilityNoResults />
          </Box>
        ) : (
          <div ref={divRef} className="availability-list">
            {/* TODO?: add search by lodging ids */}

            <ReactList
              ref={listRef}
              itemRenderer={(index: number) => {
                if (
                  hotelAvailabilityCallState ===
                  HotelAvailabilityCallState.InitialMapSearchCallInProcess
                ) {
                  return (
                    <HotelAvailabilitySkeleton
                      key={index}
                      isMobile={matchesMobile}
                    />
                  );
                } else if (index < sortedLodgings.length) {
                  return (
                    <React.Fragment key={index}>
                      {index % LODGING_COUNT_BETWEEN_PRICE_DROP_BANNER ===
                        0 && <PriceDropProtectionBannerWrapper />}
                      {renderEachLodging(sortedLodgings[index], index)}
                    </React.Fragment>
                  );
                }
                // render CardSkeleton when it's not Complete
                else if (
                  hotelAvailabilityCallState !==
                  HotelAvailabilityCallState.Complete
                ) {
                  return (
                    <HotelAvailabilitySkeleton
                      key={index}
                      isMobile={matchesMobile}
                    />
                  );
                }
                // render MoreResults footer when it's Complete
                else if (
                  hotelAvailabilityCallState ===
                  HotelAvailabilityCallState.Complete
                ) {
                  return (
                    <>
                      {matchesMobile ? (
                        <MobilePopoverCard
                          open={openDatesModal}
                          onClose={() => setOpenDatesModal(false)}
                          fullScreen={true}
                          className="mobile-dates-selection-root"
                          contentClassName="mobile-dates-selection-content-wrapper"
                          topRightButton={
                            <ActionLink
                              className="dates-selection-close-button"
                              content={<CloseButtonIcon />}
                              label="Close"
                              onClick={() => setOpenDatesModal(false)}
                            />
                          }
                        >
                          <Box className="mobile-dates-selection-content-container">
                            <MobileCalendarPicker
                              onComplete={() => {
                                setOpenDatesModal(false);
                              }}
                            />
                          </Box>
                        </MobilePopoverCard>
                      ) : (
                        <DesktopCalendarPicker
                          open={openDatesModal}
                          closePopup={() => {
                            setOpenDatesModal(false);
                          }}
                          fromProp={fromProp}
                          untilProp={untilProp}
                          onClickDone={() => {
                            setRefetchFromCalendarModal &&
                              setRefetchFromCalendarModal(true);
                          }}
                        />
                      )}
                      <GenericShopListFooter
                        className={clsx(
                          "availability-list-find-more-results",
                          "b2b"
                        )}
                        title={
                          showPremiumStaysOnly
                            ? textConstants.PREMIUM_STAYS_FIND_MORE_RESULTS_TITLE_TEXT
                            : textConstants.FIND_MORE_RESULTS_TITLE_TEXT
                        }
                        subtitle={
                          showPremiumStaysOnly
                            ? textConstants.PREMIUM_STAYS_FIND_MORE_RESULTS_SUBTITLE_TEXT
                            : textConstants.FIND_MORE_RESULTS_SUBTITLE_TEXT
                        }
                        buttons={
                          showPremiumStaysOnly
                            ? [
                                {
                                  title: textConstants.CLEAR_FILTERS_AND_SEARCH,
                                  className: clsx(
                                    "find-more-results-button",
                                    "adjust-dates",
                                    "b2b"
                                  ),
                                  isPrimary: true,
                                  onClick: () => {
                                    resetFilters();
                                    const scrollContainer =
                                      containerRef.current?.parentElement;
                                    scrollContainer?.scrollTo({
                                      top: 0,
                                      behavior: "smooth",
                                    });
                                  },
                                },
                              ]
                            : [
                                {
                                  title: textConstants.ADJUST_DATES_TEXT,
                                  className: clsx(
                                    "find-more-results-button",
                                    "adjust-dates",
                                    "b2b"
                                  ),
                                  isPrimary: true,
                                  onClick: () => setOpenDatesModal(true),
                                },
                                {
                                  title: textConstants.SEARCH_AGAIN_TEXT,
                                  className: clsx(
                                    "find-more-results-button",
                                    "search-again",
                                    "b2b"
                                  ),
                                  isPrimary: false,
                                  onClick: () => {
                                    const path = staysSearchEnabled
                                      ? PATH_HOME_STAYS
                                      : PATH_HOME;
                                    history.push(path);
                                  },
                                },
                              ]
                        }
                        isMobile={matchesMobile}
                      />
                    </>
                  );
                }

                return <></>;
              }}
              length={sortedLodgings.length + 1}
              type="variable"
              itemSizeEstimator={() =>
                APPROXIMATE_HOTEL_AVAILABILITY_CARD_HEIGHT
              }
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

interface IHotelAvailabilitySkeletonProps {
  isMobile: boolean;
}

const HotelAvailabilitySkeleton = (props: IHotelAvailabilitySkeletonProps) => {
  const { isMobile } = props;

  return (
    <Box className="availability-skeleton-row">
      <Box className="hotel-availability-card-wrapper">
        <HotelAvailabilityCard isSkeleton={true} isMobile={isMobile} />
      </Box>
    </Box>
  );
};
