import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getReviewCardHeader } from "./constants";
import {
  AirlineIcon,
  ExpandableCard,
  ExpandableCardContent,
  FlightCombinationBanner,
  FlightSummaryRow,
  formatInterval,
  getTotalPriceText,
  Icon,
  IconName,
  MixedCabinToolTip,
  removeTimezone,
  useDeviceTypes,
} from "halifax";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router-dom";
import { ReviewFlightItineraryConnectorProps } from "./container";
import * as constants from "./constants";
import { Airport, FareDetails, FlightShopStep, TripDetails } from "redmond";
import {
  airlinesCountTripSegment,
  getSliceIndex,
} from "../../../flight-shop/components/FlightList/components/FlightDetails/component";
import "./styles.scss";
import { ReviewFlightDetails } from "./components/ReviewFlightDetails";
import H from "history";
import clsx from "clsx";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileFlightDetailsModal } from "../../../flight-shop/components/MobileFlightDetailsModal";

enum OpenModalEnum {
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
}
export type IOpenModal = OpenModalEnum | false;

interface IReviewFlightItineraryProps
  extends ReviewFlightItineraryConnectorProps,
    RouteComponentProps {
  showChangeCTAs?: boolean;
  setOpenChangeOutboundFlightModal?: (arg: boolean) => void;
}

export const ReviewFlightItinerary = ({
  selectedPackageByLodging,
  airports,
  tripDetails,
  departureDate,
  returnDate,
  setPackagesFlightShopProgress,
  fareDetails,
  setOpenChangeOutboundFlightModal,
  history,
  isMultiTicket,
  isVoidWindowEligible,
  showChangeCTAs = false,
}: IReviewFlightItineraryProps) => {
  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);
  const [expandedCardKey, setExpandedCardKey] = useState<string>("");
  const [openModal, setOpenModal] = useState<IOpenModal>(false);
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    if (fareDetails) {
      setIsOutgoingMixedClass(constants.getIsMixedClass(fareDetails.slices[0]));

      setIsReturnMixedClass(constants.getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (!selectedPackageByLodging) return null;

  const handleCardKeyChange = (cardKey: string) => {
    setExpandedCardKey(cardKey === expandedCardKey ? "" : cardKey);
  };

  const renderReviewItineraryCards = () => {
    return (
      <>
        {departureDate && fareDetails && (
          <Box className="review-flight-itinerary outbound">
            <Typography className="card-header">
              {renderCardHeader(
                getReviewCardHeader(
                  true,
                  airports[tripDetails.slices[0].destinationCode]
                    ? airports[tripDetails.slices[0].destinationCode].cityName
                    : tripDetails.slices[0].destinationName,
                  dayjs(
                    removeTimezone(tripDetails.slices[0].departureTime)
                  ).toDate(),
                  true,
                  airports[tripDetails.slices[0].destinationCode]
                    ? airports[tripDetails.slices[0].destinationCode].code
                    : undefined
                )
              )}
              {isOutgoingMixedClass && <MixedCabinToolTip />}
            </Typography>
            <ExpandableCard
              className="review-itinerary-card"
              isMobile={matchesMobile}
              expandedCardKey={expandedCardKey}
              cardKey={constants.DEPARTURE_KEY}
              handleCardKeyChange={() => {
                handleCardKeyChange(constants.DEPARTURE_KEY);
              }}
              content={getExpandableCardContent({
                tripDetails,
                fareDetails,
                isMobile: matchesMobile,
                sliceDate: departureDate,
                sliceIndex: 0,
                setPackagesFlightShopProgress,
                airports,
                setOpenChangeFlightModal: setOpenChangeOutboundFlightModal,
                isMixedCabinClass: isOutgoingMixedClass,
                history,
                // setOpenMultipleAirlinesFares,
              })}
            />
          </Box>
        )}
        {returnDate && fareDetails && (
          <Box className="review-flight-itinerary return">
            <Typography className="card-header">
              {renderCardHeader(
                getReviewCardHeader(
                  false,
                  airports[tripDetails.slices[1].destinationCode]
                    ? airports[tripDetails.slices[1].destinationCode].cityName
                    : tripDetails.slices[1].destinationName,
                  dayjs(
                    removeTimezone(tripDetails.slices[1].departureTime)
                  ).toDate(),
                  true,
                  airports[tripDetails.slices[1].destinationCode]
                    ? airports[tripDetails.slices[1].destinationCode].code
                    : undefined
                )
              )}
              {isReturnMixedClass && <MixedCabinToolTip />}
            </Typography>
            <ExpandableCard
              className="review-itinerary-card"
              isMobile={matchesMobile}
              expandedCardKey={expandedCardKey}
              cardKey={constants.RETURN_KEY}
              handleCardKeyChange={() => {
                handleCardKeyChange(constants.RETURN_KEY);
              }}
              content={getExpandableCardContent({
                tripDetails,
                fareDetails,
                isMobile: matchesMobile,
                sliceDate: returnDate,
                sliceIndex: 1,
                setPackagesFlightShopProgress,
                airports,
                isMixedCabinClass: isReturnMixedClass,
                history,
                // setOpenMultipleAirlinesFares,
              })}
            />
          </Box>
        )}
      </>
    );
  };
  const renderMobileReviewItineraryCards = () => {
    return (
      <Box className="mobile-flight-itinerary-cards-section">
        {departureDate && fareDetails && (
          <Box className="mobile-review-flight-itinerary outbound">
            {renderMobileFlightSummaryRow(
              true,
              tripDetails,
              () => {
                setOpenModal(OpenModalEnum.DEPARTURE);
              },
              airports,
              isOutgoingMixedClass,
              history,
              setPackagesFlightShopProgress,
              setOpenChangeOutboundFlightModal,
              showChangeCTAs
            )}
          </Box>
        )}
        {returnDate && fareDetails && (
          <Box className="mobile-review-flight-itinerary return">
            {renderMobileFlightSummaryRow(
              false,
              tripDetails,
              () => {
                setOpenModal(OpenModalEnum.RETURN);
              },

              airports,
              isReturnMixedClass,
              history,
              setPackagesFlightShopProgress,
              undefined,
              showChangeCTAs
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {isMultiTicket && (
        <FlightCombinationBanner
          className={clsx("review-itinerary-flight-combo-banner", "b2b")}
          description={constants.COMBINATION_FLIGHT_WARNING}
          toolTipCopy={constants.COMBINATION_FLIGHT_TOOLTIP}
        />
      )}
      {matchesMobile
        ? renderMobileReviewItineraryCards()
        : renderReviewItineraryCards()}

      {isVoidWindowEligible && (
        <Box className="pkg-flight-free-cancellation-container">
          {!matchesMobile && <Icon name={IconName.CheckShield} />}
          <Box className="free-cancellation-texts">
            <Typography className="title">Free cancellation</Typography>
            <Typography variant="body2">
              Cancel this flight for free within 24 hours of booking.
            </Typography>
          </Box>
        </Box>
      )}
      <MobileFlightDetailsModal
        openModal={openModal !== false}
        isDeparture={openModal === OpenModalEnum.DEPARTURE}
        tripDetails={tripDetails}
        fareDetails={fareDetails}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

const getExpandableCardContent = (args: {
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMobile: boolean;
  sliceDate: Date;
  sliceIndex: number;
  setPackagesFlightShopProgress: (progress: FlightShopStep) => void;
  airports: { [key: string]: Airport };
  setOpenChangeFlightModal?: (arg: boolean) => void;
  isMixedCabinClass: boolean;
  history: H.History;
  // setOpenMultipleAirlinesFares: (value: boolean) => void;
}) => {
  const {
    tripDetails,
    fareDetails,
    isMobile,
    sliceIndex,
    setPackagesFlightShopProgress,
    setOpenChangeFlightModal,
    isMixedCabinClass,
    history,

    // setOpenMultipleAirlinesFares,
  } = args;

  const title = renderDesktopFlightSummaryRow(
    sliceIndex,
    tripDetails,
    fareDetails,
    isMobile,
    history,
    setPackagesFlightShopProgress,
    setOpenChangeFlightModal
  );

  const cardContent: ExpandableCardContent = {
    title,
    expandedTitle: title,
    body: (
      <ReviewFlightDetails
        isOutgoing={sliceIndex === 0}
        fareDetails={fareDetails}
        isMixedCabinClass={isMixedCabinClass}

        // setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
      />
    ),
  };

  return cardContent;
};

const renderCardHeader = (header: string) => {
  const [fromHeader, dateHeader] = header.split(":");
  return (
    <>
      <span className="from">{fromHeader}</span>
      <span className="date">{dateHeader}</span>
    </>
  );
};

const renderDesktopFlightSummaryRow = (
  tripSliceIndex: number,
  tripDetails: TripDetails,
  fareDetails: FareDetails,
  isMobile: boolean,

  history: H.History,
  setPackagesFlightShopProgress: (progress: FlightShopStep) => void,
  setOpenChangeFlightModal?: (arg: boolean) => void
) => {
  const sliceIndex = getSliceIndex(tripSliceIndex === 0, fareDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];

  const fareDetailsSlice = fareDetails.slices[sliceIndex];
  const paxPricing = fareDetailsSlice?.paxPricings
    ? fareDetailsSlice.paxPricings[0]
    : undefined;

  const airlinesCount = airlinesCountTripSegment(tripSlice.segmentDetails);

  return (
    <>
      <FlightSummaryRow
        className="review-pkg-itinerary-flight-summary"
        airlineCode={firstTripSegment.airlineCode}
        airline={firstTripSegment.airlineName}
        fareClass={fareDetailsSlice.fareShelf?.brandName ?? ""}
        airlinesCount={airlinesCount}
        departureTime={dayjs(removeTimezone(tripSlice.departureTime)).format(
          "h:mm A"
        )}
        arrivalTime={dayjs(removeTimezone(tripSlice.arrivalTime)).format(
          "h:mm A"
        )}
        departureCode={tripSlice.originCode}
        arrivalCode={tripSlice.destinationCode}
        duration={formatInterval(
          dayjs(tripSlice.arrivalTime).diff(
            dayjs(tripSlice.departureTime),
            "minute",
            true
          )
        )}
        numStops={tripSlice.stops}
        layoverString={constants.createStopoverString(tripSlice) || ""}
        bestFlightText={
          fareDetailsSlice.fareScore?.isBest
            ? constants.bestFlightText
            : undefined
        }
        cheapestFlightText={
          fareDetailsSlice.fareScore?.isCheapest
            ? constants.cheapestFlightText
            : undefined
        }
        bestQualityText={
          fareDetailsSlice.fareScore?.isBestQuality
            ? constants.bestQualityText
            : undefined
        }
        fastestText={
          fareDetailsSlice.fareScore?.isFastest
            ? constants.fastestText
            : undefined
        }
        price={
          paxPricing
            ? getTotalPriceText({ price: paxPricing.pricing.baseAmount.fiat })
            : ""
        }
        isMobile={isMobile}
        flightNumber={firstTripSegment.flightNumber}
      />
      <div className="review-itinerary-expanded-title-actions-wrapper">
        <Button
          className="review-itinerary-change-button b2b"
          onClick={
            setOpenChangeFlightModal
              ? (event: React.MouseEvent) => {
                  event.stopPropagation();
                  setOpenChangeFlightModal(true);
                }
              : (event: React.MouseEvent) => {
                  event.stopPropagation();
                  setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
                  history.goBack();
                }
          }
        >
          {constants.CHANGE}
        </Button>
        <Typography className="ctas-separator">|</Typography>
        <span className="view-details">{constants.VIEW_DETAILS}</span>
      </div>
    </>
  );
};

const renderMobileFlightSummaryRow = (
  isDeparture: boolean,
  tripDetails: TripDetails,
  setOpen: () => void,
  airports: { [key: string]: Airport },
  isMixedClass: boolean,
  history: H.History,
  setPackagesFlightShopProgress: (progress: FlightShopStep) => void,
  setOpenChangeFlightModal?: (arg: boolean) => void,
  showChangeButton?: boolean
) => {
  const sliceIndex = getSliceIndex(isDeparture, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];
  const airlinesCount = airlinesCountTripSegment(tripSlice.segmentDetails);

  return (
    <Box className="airline-details-with-chevron" onClick={setOpen}>
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          {renderCardHeader(
            getReviewCardHeader(
              isDeparture,
              airports[tripDetails.slices[isDeparture ? 0 : 1].destinationCode]
                ? airports[
                    tripDetails.slices[isDeparture ? 0 : 1].destinationCode
                  ].cityName
                : tripDetails.slices[isDeparture ? 0 : 1].destinationName,
              dayjs(
                removeTimezone(
                  tripDetails.slices[isDeparture ? 0 : 1].departureTime
                )
              ).toDate(),
              true,
              airports[tripDetails.slices[isDeparture ? 0 : 1].destinationCode]
                ? airports[
                    tripDetails.slices[isDeparture ? 0 : 1].destinationCode
                  ].code
                : undefined
            )
          )}
        </Typography>

        {((isDeparture && isMixedClass) || (!isDeparture && isMixedClass)) && (
          <MixedCabinToolTip showDivider={false} />
        )}

        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Typography variant="body1">
              {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
                "h:mm A"
              )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
                "h:mm A"
              )}`}
            </Typography>
            <Box className="card-airline-container">
              <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
              <Typography variant="body2">
                {firstTripSegment.airlineName}
                {Boolean(airlinesCount) && (
                  <span className="vi-more red-text">{`+ ${airlinesCount} other`}</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {constants.getStopsString(tripSlice.stops)}
            </Typography>
          </Box>
        </Box>
        {showChangeButton && (
          <Button
            className="review-itinerary-change-button b2b"
            onClick={
              setOpenChangeFlightModal
                ? (event: React.MouseEvent) => {
                    event.stopPropagation();
                    setOpenChangeFlightModal(true);
                  }
                : (event: React.MouseEvent) => {
                    event.stopPropagation();
                    setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
                    history.goBack();
                  }
            }
          >
            {constants.CHANGE_FLIGHT}
          </Button>
        )}
      </Box>
      <FontAwesomeIcon className="mobile-right-chevron" icon={faChevronRight} />
    </Box>
  );
};
