import dayjs from "dayjs";
import { History } from "history";
import queryStringParser from "query-string";

import {
  IResult,
  Lodging,
  LodgingSelection,
  LodgingSelectionEnum,
  StayTypesEnum,
} from "redmond";

import {
  HOTELS_PATH_AVAILABILITY,
  PATH_ANCILLARY_CUSTOMIZE,
  PATH_AVAILABILITY,
  PATH_BOOK,
  PATH_HOME,
  PATH_SHOP,
  PATH_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_SHOP,
} from "../../../utils/paths";

export interface IPremierCollectionShopParsedQuery {
  lodgingId: string;
  fromDate: string;
  untilDate: string;
  adultsCount: number;
  childrenCount: number;
  selectedLodgingIndex: number;
  lodgingSelection?: LodgingSelection | string;
  fromHotelAvailability?: boolean;
}

export interface PremierCollectionShopQuery {
  lodgingId: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  selectedAccountIndex: number;
  selectedLodgingIndex: number | null;
  lodgingSelection?: LodgingSelection | string; // this needs to be updated once we get data returned like hotels with lodgingSelection
}

interface CommonSearchParams {
  location: IResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
}

interface PremierCollectionSearchParams extends CommonSearchParams {
  type: StayTypesEnum.Hotels;
}

interface VacationRentalsSearchParams extends CommonSearchParams {
  type: StayTypesEnum.VacationRentals;
  petsCount: number;
}

export type SearchParams =
  | PremierCollectionSearchParams
  | VacationRentalsSearchParams;

type OmitNull<T> = T extends null ? never : T;
type NotNull<T> = {
  [k in keyof T]: OmitNull<T[k]>;
};
export type RequiredSearchParams = NotNull<SearchParams>;

export const transformToStringifiedQuery = ({
  lodgingId,
  fromDate,
  untilDate,
  adultsCount = 2,
  childrenCount = 0,
  selectedAccountIndex = 0,
  selectedLodgingIndex,
  lodgingSelection,
}: PremierCollectionShopQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?lodgingId=${lodgingId}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  const lodgingSelectionParam = encodeURIComponent(lodgingSelection as string);
  let string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}&childrenCount=${childrenCount}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;

  if (selectedLodgingIndex != null) {
    string += `&selectedLodgingIndex=${selectedLodgingIndex}`;
  }
  return string;
};

export const transformToStringifiedAvailabilityQuery = ({
  location,
  fromDate: initialFromDate,
  untilDate: initialUntilDate,
  adultsCount,
  childrenCount,
  petsCount,
}: {
  location: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number | null;
  childrenCount: number | null;
  petsCount?: number;
}): string => {
  if (initialFromDate === null || initialUntilDate === null) {
    return `?locationName=${location}`;
  }

  // Since we're mutating the dates below, we need to create a copy first,
  // or the dates will keep getting offset.
  const fromDate = new Date(initialFromDate);
  const untilDate = new Date(initialUntilDate);

  // fixes "off by 1 day" bug caused by converting to local timezone
  fromDate.setMinutes(fromDate.getMinutes() + fromDate.getTimezoneOffset());
  untilDate.setMinutes(untilDate.getMinutes() + untilDate.getTimezoneOffset());

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  let string = `?locationName=${location}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}&childrenCount=${childrenCount}`;
  if (petsCount) {
    string += `&petsCount=${petsCount}`;
  }
  return string;
};

export const goToAvailability = ({
  history,
  lodging,
  fromDate,
  untilDate,
  adultsCount,
  childrenCount,
  petsCount,
  isShopDetails = false,
}: {
  history: History;
  lodging: Lodging | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  childrenCount: number;
  petsCount?: number;
  isShopDetails?: boolean;
}) => {
  // note: this needs to be updated once availability params are fixed. refreshing on /availability kicks back to /search and this also kicks back to /search
  // isShopDetails forces the redirect to return to the /premium-stays page
  if (!lodging || isShopDetails) {
    return history.push(PATH_HOME);
  }

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  const fromHotelAvailability =
    !!parsedQueryStringPrimitive.fromHotelAvailability;

  const lodgingSelection = fromHotelAvailability
    ? JSON.parse(
        decodeURIComponent(
          parsedQueryStringPrimitive.lodgingSelection as string
        )
      )
    : (parsedQueryStringPrimitive.lodgingSelection as string);

  const locationToSearch = fromHotelAvailability
    ? lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
      ? lodgingSelection.searchTerm
      : `${lodging.lodging.city}, ${
          lodging.lodging.state ? `${lodging.lodging.state?.name}` : ""
        }`
    : lodgingSelection;

  const search = transformToStringifiedAvailabilityQuery({
    location: encodeURIComponent(locationToSearch),
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    petsCount,
  });

  history.push(
    `${
      !!parsedQueryStringPrimitive.fromHotelAvailability
        ? HOTELS_PATH_AVAILABILITY
        : PATH_AVAILABILITY
    }${search}`
  );
};

export const goToShop = ({ history }: { history: History }) => {
  const isVR = history.location.pathname.includes("vacation-rentals");
  history.push(
    `${isVR ? PATH_VACATION_RENTALS_SHOP : PATH_SHOP}${
      history.location.search
    }`,
    {
      fromPage: location.pathname,
    }
  );
};

export const goToAncillaryCustomize = ({ history }: { history: History }) => {
  history.push(`${PATH_ANCILLARY_CUSTOMIZE}${history.location.search}`);
};

/*
  note: if we eventually decide to have the user stay on the checkout page after a page refresh (e.g, it currently
  redirects the user to hotel/shop), this helper function will need to be extended such that it will set the user's
  URL query to help with page refresh.
*/
export const goToCheckout = ({ history }: { history: History }) => {
  history.push(`${PATH_BOOK}${history.location.search}`);
};

export const goToVacationRentalsCheckout = ({
  history,
}: {
  history: History;
}) => {
  history.push(`${PATH_VACATION_RENTALS_BOOK}${history.location.search}`);
};

export const handlePageRedirectOnSelectRoomProduct = ({
  history,
  isAddOnOptionAvailable,
}: {
  history: History;
  isAddOnOptionAvailable?: boolean;
}) => {
  if (isAddOnOptionAvailable) {
    goToAncillaryCustomize({ history });
  } else {
    goToCheckout({ history });
  }
};
