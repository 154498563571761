import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelAvailabilityMapTooltip,
  Icon,
  IconName,
  getTotalPriceText,
  genericPriceFormatter,
} from "halifax";
import React, { useContext, useState } from "react";
import {
  CorpLodging,
  getLodgingTrackingProperties,
  HOTEL_LIST_MAP_CAROUSEL,
  IIdLodgings,
  Lodging,
  LodgingId,
  SELECTED_PACKAGE_HOTEL_FROM_MAP,
} from "redmond";
import config from "../../../../../../../../../cap1-application/b2b-base/src/utils/config";
import { ClientContext } from "../../../../../../App";
import { trackEvent } from "../../../../../../api/v0/analytics/trackEvent";
import { ConnectedAvailabilityMapPricePinProps } from "./container";
import "./styles.scss";
import { ISetLodgingIdInFocus } from "../../../../actions/actions";
import { PATH_HOTEL_SHOP } from "../../../../../../utils/paths";
import { transformToStringifiedQuery } from "../../../../../hotel-shop/utils/queryStringHelpers";

export interface IAvailabilityMapPricePinProps
  extends ConnectedAvailabilityMapPricePinProps {
  lodging: Omit<Lodging, "price">;
  isInFocus: boolean;
  isHovered: boolean;
  isPreviouslyShown: boolean;
  nightCount: number | null;
  isPreview?: boolean;
  isDesktop?: boolean;
  setLodgingIdInFocus: (
    lodgingId: LodgingId | null
  ) => ISetLodgingIdInFocus | void;
}

export const AvailabilityMapPricePin = (
  props: IAvailabilityMapPricePinProps
) => {
  const {
    lodging,
    isInFocus,
    isHovered,
    isPreviouslyShown,
    nightCount,
    setLodgingIdInFocus,
    isPreview,
    isDesktop,
    accountReferenceId,
    packagesByLodgingId,
    travelersCount,
    origin,
    packageHotelQueryParams,
    searchedLocation,
    packagesViewedHotelListProperties,
  } = props;

  const [hovered, setHovered] = useState(isHovered);
  const { policies } = useContext(ClientContext);
  //   const [showPolicyModal, setShowPolicyModal] = useState<string>("");

  const tenant = config.TENANT;

  const isCorporate = "corporateTravel" in lodging.lodging;
  //   const outOfPolicyCopy = isAutoApprovalEnabled
  //     ? "If you wish to proceed with your selection, admins will be notified upon booking that this hotel was out of policy."
  //     : "This hotel rate is out of your company policy. You can continue anyway or change your selection.";

  //   const modalType = isAutoApprovalEnabled
  //     ? "out_of_policy_auto"
  //     : "out_of_policy";

  const onClickPricePin = (e: React.MouseEvent) => {
    e.stopPropagation();
    isInFocus
      ? setLodgingIdInFocus(null)
      : setLodgingIdInFocus(lodging.lodging.id);
  };

  const isInPolicy =
    isCorporate &&
    (lodging as CorpLodging).lodging.corporateTravel.policyCompliance
      .isInPolicy;

  const hasCorporate = isCorporate && lodging.hasCorporateRates;

  if (!packagesByLodgingId?.[lodging.lodging.id]?.packageDetails.pricing) {
    return null;
  }

  const price =
    packagesByLodgingId?.[lodging.lodging.id]?.packageDetails.pricing;

  const onCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setLodgingIdInFocus(null);
  };

  const onClickHotelDetails = (e: React.MouseEvent) => {
    e.stopPropagation();
    setLodgingIdInFocus(lodging.lodging.id);

    const packagePricingDetails =
      packagesByLodgingId?.[lodging.lodging.id]?.packageDetails.pricing;

    const packageDiscount =
      packagePricingDetails?.totalPackageSavings.fiat.value ?? 0;

    const packageDiscountPercentage =
      packageDiscount && packagePricingDetails
        ? (packageDiscount /
            packagePricingDetails?.strikethroughTotalPrice.fiat.value) *
          100
        : 0;

    trackEvent({
      eventName: SELECTED_PACKAGE_HOTEL_FROM_MAP,
      properties: {
        ...lodging?.trackingPropertiesV2?.properties,
        ...packagesViewedHotelListProperties.properties,
        is_preferred_cot: lodging.isPreferred,
        package_discount: packageDiscount,
        package_discount_percentage: packageDiscountPercentage,
        ...getLodgingTrackingProperties(lodging).properties,
      },
      encryptedProperties: [
        lodging?.trackingPropertiesV2?.encryptedProperties ?? "",
        ...packagesViewedHotelListProperties.encryptedProperties,
        ...getLodgingTrackingProperties(lodging).encryptedProperties,
      ],
    });

    if (origin && packageHotelQueryParams.fromDate !== null) {
      const params = transformToStringifiedQuery({
        lodgingId: lodging.lodging.id,
        origin: origin?.id?.code?.code,
        destination: (searchedLocation?.id as IIdLodgings).lodgingSelection
          .searchTerm,
        ...packageHotelQueryParams,
        fromDate: packageHotelQueryParams.fromDate,
      });

      window.open(`${PATH_HOTEL_SHOP}${params}`, "_blank");
    }
  };

  React.useEffect(() => {
    setHovered(isHovered);
  }, [isHovered]);

  return (
    <Box
      className={clsx(
        "lodging-marker-wrapper",
        { inFocus: isInFocus },
        { hovered: isInFocus || hovered },
        { previouslyShown: isPreviouslyShown }
      )}
    >
      {isInFocus && isDesktop && (
        <Box
          className={clsx("lodging-details-container", {
            "in-policy": isInPolicy,
          })}
        >
          <HotelAvailabilityMapTooltip
            lodging={lodging}
            onCloseClick={(ev) => onCloseClick(ev)}
            // onClickHotelDetails={() => console.log("Update to go to shop")}
            onClickHotelDetails={(ev) => onClickHotelDetails(ev)}
            nightCount={nightCount ? nightCount : 0}
            rewardsKey={accountReferenceId || undefined}
            // isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
            // isVentureX={isVentureX}
            onViewCarouselImage={() =>
              trackEvent({
                eventName: HOTEL_LIST_MAP_CAROUSEL,
                properties: {},
              })
            }
            policyLimit={policies?.hotels.policies[0].maxPricePerNight}
            tenant={config.TENANT}
            searchedRoomsCount={isCorporate ? 1 : undefined} // [Packages TO-DO]: Update to use actual roomsCount
            // searchedRoomsCount={isCorporate ? roomsCount : undefined}
            // onViewCorpCompareBar={onOpenCompareBarTooltip(
            //   ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_MAP
            // )}
            variant="package"
            packagePricing={
              packagesByLodgingId?.[lodging.lodging.id]?.packageDetails.pricing
            }
            travelersCount={travelersCount}
          />
        </Box>
      )}

      <Button
        className={clsx("price-pin", tenant, {
          "out-of-policy": isCorporate && !isInPolicy,
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={(e) => (!isPreview ? onClickPricePin(e) : null)}
      >
        {/* {showOnSaleIcon ? (
          <Icon className="preferred-icon" name={IconName.PreferredHotelIcon} />
        ) : null} */}

        {isInPolicy && (
          <Icon className="policy-icon" name={IconName.CheckMarkGreen} />
        )}
        {hasCorporate && (
          <Icon className="loyalty-icon" name={IconName.StarCircledIcon} />
        )}
        <Box className="price-pin-label">
          {getTotalPriceText({
            price: price.totalPrice.fiat,
            priceFormatter: genericPriceFormatter,
          })}
        </Box>
      </Button>
    </Box>
  );
};
