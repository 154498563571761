import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Slide, useScrollTrigger } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID, RewardsAccount } from "redmond";

import {
  HIDDEN_BANNER_UNPROTECTED_PATHS,
  HIDDEN_HEADER_PATHS_DESKTOP,
  PRICE_MATCH_BANNER_PATHS,
} from "../../../utils/urlPaths";
import "./styles.scss";
import { CapOneHeaderHomePageRightSection } from "../Header/components/DesktopHeader";

export interface IRootBannerProps extends RouteComponentProps {
  headerDisplayed?: boolean;
  isHidden?: boolean;
  canViewConsumer: boolean;
  rewardsAccounts?: RewardsAccount[];
  hideOnScroll?: boolean;
}

const HideOnScroll = ({ children }: { children: JSX.Element }) => {
  const trigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export const RootBanner = (props: IRootBannerProps) => {
  const { headerDisplayed, isHidden, ...browserRouterProps } = props;
  const { history } = browserRouterProps;
  const { matchesMobile } = useDeviceTypes();
  const [renderRightNav, setRenderRightNav] = useState(true);

  useEffect(() => {
    if (!matchesMobile) {
      if (HIDDEN_HEADER_PATHS_DESKTOP.includes(history.location.pathname)) {
        setRenderRightNav(false);
      } else {
        setRenderRightNav(true);
      }
    }
  }, [history.location.pathname, matchesMobile]);

  const content = (
    <Box
      className={clsx(
        "b2b-portal-banner-root",
        "corp",
        "home-screen-redesign",
        {
          "includes-right-section": renderRightNav && !matchesMobile,
          mobile: matchesMobile,
          "checkout-banner": PRICE_MATCH_BANNER_PATHS.includes(
            history.location.pathname
          ),
          isHidden,
        }
      )}
    >
      <div
        id={B2B_PORTAL_BANNER_CONTAINER_ID}
        className={clsx({
          mobile: matchesMobile,
          isHidden: HIDDEN_BANNER_UNPROTECTED_PATHS.includes(
            history.location.pathname
          ),
        })}
      />

      {renderRightNav && !matchesMobile && (
        <CapOneHeaderHomePageRightSection displayOnlyIcon {...props} />
      )}
    </Box>
  );

  return props.hideOnScroll ? <HideOnScroll>{content}</HideOnScroll> : content;
};
