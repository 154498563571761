import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Divider } from "@material-ui/core";
import { Close, Edit } from "@material-ui/icons";
import { ICorpPerson, IPerson, ITravelerStepErrors } from "redmond";
import { EmailInputField } from "halifax";
import { LoyaltyInformationForm } from "../../LoyaltyInformationForm";

interface HotelEditTravelerInfoFormProps {
  traveler: ICorpPerson | IPerson;
  disableEditTravelerInfo: boolean;
  handleChange: (
    traveler: ICorpPerson | IPerson,
    newLoyaltyNumber?: string,
    newEmail?: string
  ) => void;
  handleRemove: (traveler: ICorpPerson | IPerson) => void;
  setIsEditModalOpen: (value: boolean) => void;
  showErrors: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  isLoyaltyEligible: boolean;
  isAmadeusProvider: boolean;
}

export const HotelEditTravelerInfoForm = ({
  traveler,
  disableEditTravelerInfo,
  handleChange,
  handleRemove,
  setIsEditModalOpen,
  showErrors,
  setShowErrors,
  isLoyaltyEligible,
  isAmadeusProvider,
}: HotelEditTravelerInfoFormProps) => {
  const [contactEmail, setContactEmail] = useState<string>(
    () => traveler.emailAddress ?? ""
  );

  useEffect(() => {
    setContactEmail(traveler.emailAddress ?? "");
  }, [traveler.emailAddress]);

  return (
    <>
      <fieldset
        className={"hotel-edit-traveler-info-form-container"}
        key={traveler.id}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" className="title">
            {traveler.givenName} {traveler.surname}
          </Typography>
          <Box display="flex">
            <Button
              variant="text"
              color="primary"
              endIcon={<Edit />}
              disabled={disableEditTravelerInfo}
              aria-label={`Edit traveler ${traveler.givenName} ${traveler.surname}`}
              onClick={() => setIsEditModalOpen(true)}
            >
              Edit
            </Button>
            <Divider
              orientation="vertical"
              style={{ backgroundColor: "#606060", margin: "12px" }}
              flexItem
            />
            <Button
              variant="text"
              color="primary"
              endIcon={<Close />}
              disabled={disableEditTravelerInfo}
              onClick={() => handleRemove(traveler)}
              aria-label={`Remove traveler ${traveler.givenName} ${traveler.surname}`}
            >
              Remove
            </Button>
          </Box>
        </Box>
        {isLoyaltyEligible && (
          <>
            <Divider style={{ margin: 0 }} />
            <LoyaltyInformationForm
              onChange={(value) => {
                handleChange(traveler, value, undefined);
              }}
              disabled={disableEditTravelerInfo}
              traveler={traveler}
              isAmadeusProvider={isAmadeusProvider}
              setShowLoyaltyError={(isError) =>
                setShowErrors?.({
                  phone: showErrors?.phone,
                  email: showErrors?.email,
                  travelerSelect: showErrors?.travelerSelect,
                  corpPrimaryTraveler: isError,
                })
              }
            />
          </>
        )}
        <Divider style={{ margin: 0 }} />
        <div className="form-container">
          <label htmlFor="contact-email-input-field">
            <Typography className="title" component="span">
              Contact information
            </Typography>
          </label>
          <Typography className="subtitle" variant="body1">
            A copy of the hotel confirmation email will be sent to this
            traveler.
          </Typography>
          <EmailInputField
            fullWidth
            label="Email (Optional)"
            onChange={(value) => {
              setContactEmail(value);
              handleChange(traveler, undefined, value);
            }}
            disabled={disableEditTravelerInfo}
            showError={showErrors?.corpPrimaryTraveler}
            email={contactEmail}
            setShowEmailError={(isError) =>
              setShowErrors?.({
                phone: showErrors?.phone,
                email: showErrors?.email,
                travelerSelect: showErrors?.travelerSelect,
                corpPrimaryTraveler: isError,
              })
            }
          />
        </div>
      </fieldset>
    </>
  );
};
