import React, { useContext, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { DesktopPackagesBookWorkflowConnectorProps } from "./container";
import {
  B2BSpinner,
  ButtonWrap,
  CheckoutStepper,
  getHSPEarnOfferDescription,
  Icon,
  IconName,
  LoadingIndicator,
} from "halifax";
import clsx from "clsx";
import { PATH_BOOK_CONFIRMATION, PATH_HOME } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import {
  CHANGE_CTA_TEXT,
  FLIGHT_TRAVELERS_HEADING,
  HEADER_MAIN_TEXT,
  HEADER_SUB_TEXT,
  HOTEL_TRAVELERS_HEADING,
  LOADING_TEXT,
  PAYMENT_HEADING,
  PAYMENT_SUBHEADING,
  SEAT_SELECTION_HEADING,
  SEAT_SELECTION_NOT_AVAILABLE,
  SELECTED_SEATS_COMPLETED_TEXT,
  SKIPPED_SEATS_COMPLETED_TEXT,
} from "./textConstants";
import {
  PackagesShopProgressBar,
  PackageDetailsPanel,
  PackagesPriceBreakdown,
  FlightBookPassengerSelection,
  PackageTreesCard,
} from "../";
import {
  FlightPassengerSelectors,
  GenericChildState,
  getChildState,
  getParentState,
  ParentState,
  RewardsPaymentEventTypes,
  SeatSelectors,
  useCheckoutState,
  useCheckoutStateSelector,
  WalletSelectors,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { PackagesContactInfo } from "../ContactInfo";
import { HotelBookPassengerSelection } from "../HotelBookPassengerSelection";
import { LodgingSelectors } from "../../state/selectors";
import { BookingInProgressModal } from "../BookingInProgressModal";
import { BookingErrorModal } from "../BookingErrorModal";
import { getCheckoutSteps } from "./utils";
import { SeatSelection } from "../SeatSelection";
import { PackagesRewardsAndPayment } from "../RewardsAndPayment";
import { SeatMapResponseEnum } from "@b2bportal/air-booking-api";
import { RewardsAccount, TravelProductEnum } from "redmond";
import { ConnectedPackageDiscountBanner } from "../PackageDiscountBanner";
import { goToHotelShop } from "../../../hotel-shop/utils/queryStringHelpers";
import {
  getPackageFlightCardTotal,
  getPackageHotelCardTotal,
} from "../../state/selectors/common";
import { productToEarnV2 } from "../../../../api/v1/rewards/productToEarn";

export interface IDesktopPackagesBookWorkflowProps
  extends RouteComponentProps,
    DesktopPackagesBookWorkflowConnectorProps {}

export type DesktopPackageBookValidationError =
  | "flight-travelers"
  | "contact-info"
  | "hotel-travelers"
  | "seats"
  | "rewards"
  | "card";

export const DesktopPackagesBookWorkflow = ({
  history,
  finalizePackageResponse,
  selectedLodging,
  selectedTrip,
  tripDetails,
  selectedRoom,
  hotelFromDate,
  hotelUntilDate,
  packagePricing,
  rewardsAccounts,
  searchedAdultsCount,
  searchedChildren,
  searchedInfants,
  searchedDestination,
  searchedDepartureDate,
  searchedReturnDate,
  searchedOrigin,
  recommendedFlights,
}: IDesktopPackagesBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const [validationErrorTypes, setValidationErrorTypes] =
    React.useState<DesktopPackageBookValidationError[]>();

  const flightPAXSectionRef = useRef<HTMLDivElement>(null);
  const hotelPAXSectionRef = useRef<HTMLDivElement>(null);
  const seatSelectionSectionRef = useRef<HTMLDivElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const selectedFlightPassengers = useCheckoutStateSelector(
    FlightPassengerSelectors.getAllSelectedUserPassengersParent
  );
  const selectedHotelPassenger = useCheckoutStateSelector(
    LodgingSelectors.getSelectedUserPassengerParent
  );
  const selectedSeats = useCheckoutStateSelector(
    SeatSelectors.getSelectedSeats
  );
  const seatTotalPricing = useCheckoutStateSelector(
    SeatSelectors.getSeatTotalPricing
  );
  const skippedSeatSelection = useCheckoutStateSelector(
    SeatSelectors.getSkipSeatSelection
  );
  const seatMapAvailability = useCheckoutStateSelector(
    SeatSelectors.getSeatMapAvailability
  );
  const flightTotal = useCheckoutStateSelector(getPackageFlightCardTotal);
  const hotelTotal = useCheckoutStateSelector(getPackageHotelCardTotal);
  const offerToApply = useCheckoutStateSelector(
    WalletSelectors.getSelectedOffer
  );

  const numPassengersWithSelectedSeats = React.useMemo(
    () => new Set(selectedSeats.map((seat) => seat.person_id)).size,
    [selectedSeats]
  );

  const goToFlightTravelers = () => {
    send(Event.GO_TO_PASSENGER_SELECT);
  };

  const goToHotelTravelers = () => {
    send(Event.GO_TO_HOTEL_PASSENGER_SELECT);
  };

  const goToSeatSelection = () => {
    send(Event.GO_TO_SEAT_SELECTION);
  };

  const setRewardsAccounts = (accounts: RewardsAccount[]) =>
    send({
      type: RewardsPaymentEventTypes.SET_REWARDS_ACCOUNTS,
      accounts,
    });

  React.useEffect(() => {
    switch (parentState) {
      case ParentState.passengerInformation:
        flightPAXSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.lodgingPassengerInformation:
        hotelPAXSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.seatSelection:
      case ParentState.cartUpdateForSeats:
        seatSelectionSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.cardPayment:
        paymentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  }, [parentState]);

  // initialize checkout context from redux
  React.useEffect(() => {
    if (
      selectedLodging &&
      selectedRoom &&
      selectedTrip &&
      finalizePackageResponse &&
      tripDetails &&
      hotelFromDate &&
      hotelUntilDate &&
      packagePricing &&
      searchedDestination &&
      searchedOrigin &&
      searchedDepartureDate
    ) {
      send({
        type: Event.INITIALIZE_CHECKOUT_STATE,
        payload: {
          selectedLodging,
          selectedRoom,
          selectedTrip,
          finalizePackageResponse,
          tripDetails,
          hotelFromDate,
          hotelUntilDate,
          packagePricing,
          searchedAdultsCount,
          searchedChildren,
          searchedInfants: searchedInfants.map((infant) => infant.age),
          searchedDestination,
          searchedDepartureDate,
          searchedReturnDate,
          searchedOrigin,
          benchmarkSliceIds: recommendedFlights ?? [],
        },
      });
    } else {
      goToHotelShop({ history });
    }
  }, [
    selectedLodging,
    selectedRoom,
    selectedTrip,
    finalizePackageResponse,
    tripDetails,
    hotelFromDate,
    hotelUntilDate,
    packagePricing,
    searchedAdultsCount,
    searchedChildren,
    searchedInfants,
    searchedDestination,
    searchedDepartureDate,
    searchedReturnDate,
    searchedOrigin,
    recommendedFlights,
  ]);

  React.useEffect(() => {
    // update checkout state with rewards accounts from redux
    setRewardsAccounts(rewardsAccounts);
  }, [rewardsAccounts]);

  React.useEffect(() => {
    // update path so header becomes visible for confirmation
    if (parentState === ParentState.bookingConfirmation) {
      history.replace(PATH_BOOK_CONFIRMATION);
    }
  }, [parentState]);

  React.useEffect(() => {
    send({ type: Event.SET_PLATFORM, platform: "desktop" });
  }, []);

  React.useEffect(() => {
    if (flightTotal !== undefined && hotelTotal !== undefined) {
      Promise.all(
        rewardsAccounts.map((account) =>
          productToEarnV2({
            account: account.accountReferenceId,
            earnItems: {
              [TravelProductEnum.Flights]: {
                amountUsd: flightTotal,
              },
              [TravelProductEnum.Hotels]: {
                amountUsd: hotelTotal,
                earnOfferDescription: getHSPEarnOfferDescription(offerToApply),
              },
            },
          })
            .then((res) => {
              send({
                type: RewardsPaymentEventTypes.SET_EARN_BY_REWARDS_ACCOUNT_ID,
                accountReferenceId: account.accountReferenceId,
                earnResponse: res,
              });
            })
            .catch((e) =>
              console.warn(
                `Failed to fetch earn for account ${account.accountReferenceId}: ${e}`
              )
            )
        )
      );
    }
  }, [flightTotal, hotelTotal, offerToApply]);

  return (
    <Box className="desktop-packages-book-workflow-root">
      <CheckoutStepper
        steps={getCheckoutSteps(parentState)}
        headerCopy={HEADER_MAIN_TEXT}
        subHeaderCopy={HEADER_SUB_TEXT}
        logo={
          <ButtonWrap
            className="logo"
            onClick={() => {
              history.push(PATH_HOME);
            }}
          >
            {clientContext.logo}
          </ButtonWrap>
        }
        className={clsx("b2b", "combined-step")}
      />

      <Box className="packages-book-progress-bar-wrapper">
        <PackagesShopProgressBar />
      </Box>

      <Box className="packages-book-body-root">
        <Box className="packages-book-body-wrapper">
          <Box className="packages-book-body-left">
            <PackageDetailsPanel />

            <div
              className={clsx("packages-book-section", {
                selected: [
                  ParentState.passengerInformation,
                  ParentState.passport,
                  ParentState.contactInformation,
                ].includes(parentState),
              })}
              ref={flightPAXSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {FLIGHT_TRAVELERS_HEADING}
                </Typography>

                {parentState === ParentState.loading ? (
                  <LoadingIndicator
                    className="packages-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                ) : [
                    ParentState.passengerInformation,
                    ParentState.passport,
                    ParentState.contactInformation,
                  ].includes(parentState) ? (
                  <>
                    <hr />

                    <FlightBookPassengerSelection
                      validationErrorTypes={validationErrorTypes}
                    />

                    <hr />

                    <PackagesContactInfo
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : selectedFlightPassengers.length ? (
                  <Box className="packages-book-filled-section">
                    <Icon name={IconName.User} />
                    <Typography className="packages-book-filled-section-content">
                      {selectedFlightPassengers.reduce(
                        (nameString, person, i) => {
                          const firstLastName = `${person.givenName} ${person.surname}`;
                          if (i > 0) return `${nameString}, ${firstLastName}`;

                          return firstLastName;
                        },
                        ""
                      )}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![
                ParentState.passengerInformation,
                ParentState.passport,
                ParentState.contactInformation,
              ].includes(parentState) &&
                !!selectedFlightPassengers.length && (
                  <Box className="packages-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToFlightTravelers}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("packages-book-section", {
                selected: [ParentState.lodgingPassengerInformation].includes(
                  parentState
                ),
              })}
              ref={hotelPAXSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {HOTEL_TRAVELERS_HEADING}
                </Typography>

                {[ParentState.lodgingPassengerInformation].includes(
                  parentState
                ) ? (
                  <>
                    <hr />
                    <HotelBookPassengerSelection
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : selectedHotelPassenger ? (
                  <Box className="packages-book-filled-section">
                    <Icon name={IconName.User} />
                    <Typography className="packages-book-filled-section-content">
                      {selectedHotelPassenger.givenName}{" "}
                      {selectedHotelPassenger.surname}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![ParentState.lodgingPassengerInformation].includes(
                parentState
              ) &&
                selectedHotelPassenger && (
                  <Box className="packages-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToHotelTravelers}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("packages-book-section", {
                selected: [ParentState.seatSelection].includes(parentState),
              })}
              ref={seatSelectionSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {SEAT_SELECTION_HEADING}
                </Typography>

                {((parentState === ParentState.seatSelection &&
                  childState === GenericChildState.loading) ||
                  parentState === ParentState.cartUpdateForSeats) && (
                  <LoadingIndicator
                    className="packages-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                )}

                {parentState === ParentState.seatSelection &&
                childState !== GenericChildState.loading ? (
                  <>
                    <hr />
                    <SeatSelection
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : selectedSeats.length ||
                  skippedSeatSelection ||
                  seatMapAvailability ===
                    SeatMapResponseEnum.SeatMapUnavailable ? (
                  <Box className="packages-book-filled-section">
                    {!skippedSeatSelection &&
                      seatMapAvailability ===
                        SeatMapResponseEnum.SeatMapAvailable && (
                        <Icon name={IconName.User} />
                      )}
                    <Typography className="packages-book-filled-section-content">
                      {(() => {
                        switch (true) {
                          case skippedSeatSelection:
                            return SKIPPED_SEATS_COMPLETED_TEXT;
                          case !!selectedSeats.length:
                            return SELECTED_SEATS_COMPLETED_TEXT(
                              numPassengersWithSelectedSeats,
                              seatTotalPricing
                            );
                          case seatMapAvailability ===
                            SeatMapResponseEnum.SeatMapUnavailable:
                          default:
                            return SEAT_SELECTION_NOT_AVAILABLE;
                        }
                      })()}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![ParentState.seatSelection].includes(parentState) &&
                (selectedSeats.length || skippedSeatSelection) && (
                  <Box className="packages-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToSeatSelection}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("packages-book-section", {
                selected: [
                  ParentState.wallet,
                  ParentState.rewardsPayment,
                  ParentState.cardPayment,
                ].includes(parentState),
              })}
              ref={paymentSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {PAYMENT_HEADING}
                </Typography>

                {parentState === ParentState.cartUpdate && (
                  <LoadingIndicator
                    className="packages-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                )}

                {[
                  ParentState.wallet,
                  ParentState.rewardsPayment,
                  ParentState.cardPayment,
                ].includes(parentState) ? (
                  <>
                    <Typography
                      variant="h4"
                      className="packages-book-section-subheading"
                    >
                      {PAYMENT_SUBHEADING}
                    </Typography>
                    <hr />
                    <PackagesRewardsAndPayment
                      validationErrorTypes={validationErrorTypes}
                      setValidationErrorTypes={setValidationErrorTypes}
                    />
                  </>
                ) : null}
              </Box>
            </div>

            <PackageTreesCard />
          </Box>
          <Box className="packages-book-body-right">
            <Box className="packages-book-body-right-sticky-container">
              <PackagesPriceBreakdown
                setValidationErrorTypes={setValidationErrorTypes}
              />
              <ConnectedPackageDiscountBanner />
            </Box>
          </Box>
        </Box>
      </Box>

      <BookingInProgressModal />
      <BookingErrorModal />
    </Box>
  );
};
