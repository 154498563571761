import React from "react";
import { DesktopDescriptionConnectorProps } from "./container";
import { Box, Divider, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  Icon,
  IconName,
  ActionLink,
  HotelDescription,
} from "halifax";
import { LodgingAddressExact } from "redmond";
import { PremierCollectionShopExpandedMap }  from "../PremierCollectionShopExpandedMap";

export interface DesktopDescriptionProps
  extends DesktopDescriptionConnectorProps,
    RouteComponentProps {
}

export const DesktopDescription = (props: DesktopDescriptionProps) => {
  const {
    lodging,
    shopDetailsLodgingData,
  } = props;
  // Use lodging if available, otherwise use shopDetailsLodgingData
  const { lodging: lodgingData } = lodging?.lodging ? lodging : { lodging: shopDetailsLodgingData };
  const [
    showExpandedPremierCollectionMap,
    setShowExpandedPremierCollectionMap,
  ] = React.useState(false);

  return (
    lodgingData && (
      <Box className="description-wrapper">
        <Typography variant="h2" className="description-title">
          About the
          <span className="regular-font"> {lodgingData?.name}</span>
        </Typography>
        <PremierCollectionShopExpandedMap
          lodgingData={lodgingData}
          showExpandedPremierCollectionMap={
            showExpandedPremierCollectionMap
          }
          setShowExpandedPremierCollectionMap={
            setShowExpandedPremierCollectionMap
          }
        />
        <ActionLink
        className="lodging-address-link"
        content={
            <Box className="lodging-address-link-content">
              <Icon name={IconName.B2BMapPin} />
              <Typography className="lodging-address">
                {((lodgingData?.address || lodgingData?.location) as LodgingAddressExact).line1}
              </Typography>
            </Box>
          }
          onClick={() => setShowExpandedPremierCollectionMap(true)}
        />
        <Divider className={"description-divider"} />
        <HotelDescription description={lodgingData?.description || ""} />
      </Box>
    )
  );
};
